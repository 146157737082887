@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";


.detail {
  background-color: $secondary-color;
  min-height: 100vh !important;

  .chargingContent {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }


  .content, .noNavBarContent {
    padding-top: 90px;
    min-height: 88vh;
    @media (max-width: 374px) {
      padding-top: 145px; }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        padding-left: 10%;
        font-size: $medium-font-size;

        p, h3 {
          margin-right: 10px; } } }

    .header {
      background-color: $secondary-color;
      padding-left: 5%;
      margin-bottom: -1.5rem !important; }

    .basicData {
      .document {
        margin-top: 10px;

        .documentText {
          flex: inherit; }

        .documentTypeText {
          min-width: 100px; }

        .documentViewPhotos {
          font-size: $tiny-font-size;
          color: $color-primary-1;

          font-weight: bold;
          cursor: pointer;
          margin-left: 2em; }

        .documentSecondaryBox {
          display: flex; } } }

    .emergencyData {
      .header {
        padding-left: 5%;
        font-size: $small-font-size; }

      .contact {
        border-bottom: 1px solid grey; }

      .emergencyDataRow {
        margin-top: 10px;
        padding-bottom: 10px; } }

    .medicData {
      .medicObservationRow {
        @media (max-width: $tablet) {
          align-items: flex-start;
          margin-top: 10px; }

        .medicObservation {
          @media (min-width: $tablet) {
            display: flex;
            align-items: center; }

          h3 {
            @media (max-width: $tablet) {
              margin-top: 0; } } } } } }
  .noNavBarContent {
    padding-top: 10px; } }

.detailBox {
  padding-bottom: 14rem;
  @media (max-width: $tablet) {
    padding-bottom: 16rem; } }

.errorMessage {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  justify-content: center; }

.relationshipText {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.snack {
  z-index: 200000 !important;
  div {
    background-color: $snack-warning !important;
    font-weight: bold; } }


.snackMessage {
  display: flex;
  align-items: center; }

.snackIcon {
  margin-right: 25px; }

.snackButton {
  color: #fff !important;
  border-color: #fff !important;
  margin-bottom: 5px !important; }
//.snackButton:hover
//background-color: #000 !important
.closeModalIcon {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.dialogTitle {
  text-align: center; }

.seeDataButton {
  background-color: $color-primary-1 !important;
  color: $color-secondary-1-0  !important;
  padding: 1.5em !important; }

.stepsContainer {
  background-color: $secondary-color;
  border-radius: 25px;
  margin: 1em;
  padding-top: 1em !important; }
.patientPhotoContainer {
  display: flex !important;
  justify-content: center !important;
  padding-left: 0 !important; }

.patientPhoto {
  width: 10rem !important;
  height: 10rem !important; }

.covidCertificatesContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  @media (max-width: $tablet) {
    width: 100%;
    right: unset;
    align-items: center;
    //left: 50% !important
 } }    //transform: translateX(-50%) !important
.covidCertificateButton {
  margin-top: 1rem !important;
  //position: fixed !important
  //right: 1rem !important
  //left: 50% !important
  //transform: translateX(-50%) !important
  background-color: #4fa9b3 !important;
  color: #fff !important;
  svg {
    color: #fff;
    fill: #fff; } }

