body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Aristotelica';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Aristotelica-Text-Regular-trial.e0ab01a0.ttf) format('truetype')
}

@font-face {
  font-family: 'Blogger-sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Blogger_Sans-Light.b8bf30a6.otf)
}

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  src: url(/static/media/Comfortaa-Medium.1c593a56.ttf)
}
/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes Landing_floatBracelet__1uf4W {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes Landing_floatVerticalBracelet__1yHbt {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes Landing_backgroundGradient__3LBac {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes Landing_backgroundGradient2__mdOz7 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.Landing_Landing__3Atic {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #ffffff; }
  .Landing_Landing__3Atic h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .Landing_Landing__3Atic h2 {
    color: white;
    text-align: center;
    font-size: 30px; }
    @media (max-width: 768px) {
      .Landing_Landing__3Atic h2 {
        margin-top: 0;
        font-size: 20px; } }
  .Landing_Landing__3Atic h3 {
    font-family: Blogger-sans; }
  .Landing_Landing__3Atic li {
    font-family: Blogger-sans; }
  .Landing_Landing__3Atic a {
    font-family: Blogger-sans; }
  .Landing_Landing__3Atic p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .Landing_Landing__3Atic .Landing_headContainer__JcI95 {
    display: flex;
    min-height: 100vh;
    padding: 7rem 0.5rem 0.5rem;
    align-items: center;
    background: linear-gradient(to bottom, #06beb6, #74a7b2);
    background-size: cover; }
    .Landing_Landing__3Atic .Landing_headContainer__JcI95 h1 {
      margin-bottom: 2rem;
      font-size: 60px; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_headContainer__JcI95 h1 {
          font-size: 40px; } }
    @media (min-width: 1024px) {
      .Landing_Landing__3Atic .Landing_headContainer__JcI95 .Landing_headContainerImg__2xYMO {
        margin-top: -200px; } }
    .Landing_Landing__3Atic .Landing_headContainer__JcI95 .Landing_headContainerContent__3TLLV {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem; }
      @media (min-width: 768px) {
        .Landing_Landing__3Atic .Landing_headContainer__JcI95 .Landing_headContainerContent__3TLLV {
          padding-left: 3rem;
          padding-right: 2rem; } }
    @media (min-width: 768px) {
      .Landing_Landing__3Atic .Landing_headContainer__JcI95 .Landing_videoPlayerGridItem__3FUMV {
        padding-left: 2rem;
        padding-right: 3rem; } }
  .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ {
    margin-top: 6rem;
    margin-bottom: -2rem; }
    @media (max-width: 1024px) {
      .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ {
        margin-top: 4rem;
        margin-bottom: 4rem; } }
    .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder !important; }
      @media (max-width: 1024px) {
        .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 {
          flex-direction: column; } }
      .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 svg {
        font-size: 2rem; }
        @media (min-width: 1024px) {
          .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 svg {
            margin-left: 1rem; } }
      .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 svg:first-child {
        margin-left: 0; }
        @media (min-width: 1024px) {
          .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 svg:first-child {
            margin-right: 1rem; } }
      .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 svg:last-child {
        display: none; }
        @media (min-width: 1024px) {
          .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ h1 svg:last-child {
            display: block; } }
    @media (max-width: 480px) {
      .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ .Landing_liClass__2CHPw {
        padding-left: 0; } }
    .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ p {
      color: #FFFFFF;
      font-weight: bold; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ p {
          font-size: 17px;
          padding-left: 0; } }
    .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ .Landing_dataIntroductionIcon__214fm {
      color: #ffffff; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ .Landing_dataIntroductionIcon__214fm {
          margin-right: 0; } }
      .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ .Landing_dataIntroductionIcon__214fm svg {
        font-size: 2.25rem; }
    @media (max-width: 480px) {
      .Landing_Landing__3Atic .Landing_dataIntroduction__3YAe_ {
        margin-right: 0;
        padding-right: 0; } }
  .Landing_Landing__3Atic .Landing_weHelpYouSection__38gHU {
    display: flex;
    justify-content: space-around;
    background-color: #4FA9B3;
    width: 100%;
    margin-bottom: -2px; }
    .Landing_Landing__3Atic .Landing_weHelpYouSection__38gHU p {
      text-align: center;
      color: #ffffff; }
    .Landing_Landing__3Atic .Landing_weHelpYouSection__38gHU .Landing_documentsButtonContainer__W6Pdj {
      margin-top: 1em;
      display: flex !important;
      justify-content: center !important; }
      .Landing_Landing__3Atic .Landing_weHelpYouSection__38gHU .Landing_documentsButtonContainer__W6Pdj .Landing_downloadDocumentButton__2azxw {
        background-color: #ffcf2f !important; }
      .Landing_Landing__3Atic .Landing_weHelpYouSection__38gHU .Landing_documentsButtonContainer__W6Pdj .Landing_documentsDownloadIcon__zstz6 {
        margin-right: 0.2em; }
  .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    color: #ffffff;
    position: relative;
    margin-top: -4px; }
    .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE p {
      max-width: 800px; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE p {
          max-width: 400px; } }
    .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_fondoOlasImgs__3-v-k {
      position: absolute; }
    .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_peopleContainerContent__oiwwp {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 1rem;
      max-width: 90vw; }
    .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_peopleIconContainer__360AM {
      display: flex; }
      .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_peopleIconContainer__360AM .Landing_peopleIconContent__3mrG9 {
        display: flex;
        flex-direction: column;
        align-items: center; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_peopleIconContainer__360AM p {
          text-align: center; } }
      .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_peopleIconContainer__360AM .Landing_peopleIconContent__3mrG9:hover {
        cursor: pointer; }
      .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_peopleIconContainer__360AM .Landing_peopleIconLeft__3_vJr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 50px; }
      .Landing_Landing__3Atic .Landing_peopleContainer__3yZTE .Landing_peopleIconContainer__360AM .Landing_peopleIconRigth__1cmb2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
  .Landing_Landing__3Atic .Landing_whyContainer__2ZeRO {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Landing_Landing__3Atic .Landing_whyContainer__2ZeRO h1 {
      color: #50a7b4; }
    .Landing_Landing__3Atic .Landing_whyContainer__2ZeRO .Landing_whyList__3KaxY {
      list-style-type: none;
      text-align: left;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: bold; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_whyContainer__2ZeRO .Landing_whyList__3KaxY {
          font-size: 17px;
          padding-left: 0;
          padding-right: 20px;
          margin-left: -20px; } }
      .Landing_Landing__3Atic .Landing_whyContainer__2ZeRO .Landing_whyList__3KaxY li {
        display: flex;
        align-items: center; }
    .Landing_Landing__3Atic .Landing_whyContainer__2ZeRO .Landing_whyArrowImg__3vNVl {
      transform: rotate(45deg); }
  .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%; }
    .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 {
      display: flex;
      align-items: center; }
      .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 .Landing_braceletsContainerImgBracelet__euKqo {
        animation: Landing_floatBracelet__1uf4W 6s ease-in-out infinite;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 250px; }
        @media (max-width: 480px) {
          .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 .Landing_braceletsContainerImgBracelet__euKqo {
            margin-bottom: 50px; } }
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 .Landing_braceletsContainerImgBracelet__euKqo img {
          height: 130px; }
          @media (max-width: 768px) {
            .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 .Landing_braceletsContainerImgBracelet__euKqo img {
              width: 210px; } }
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 .Landing_braceletsContainerImgBracelet__euKqo p {
          color: #448086;
          font-size: 30px; }
      .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 .Landing_braceletsContainerImgHandQR__9-p-l {
        width: 150px;
        transform: rotate(-10deg);
        margin-left: -2rem; }
        @media (max-width: 480px) {
          .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImg__kby15 .Landing_braceletsContainerImgHandQR__9-p-l {
            margin-left: -5rem; } }
    .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImgArrowLeft__2tWu8 {
      margin-top: -200px;
      margin-left: 70px; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImgArrowLeft__2tWu8 {
          display: none; } }
    .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImgArrowMobile__18f99 {
      margin-top: -90px;
      margin-left: 70px; }
      @media (min-width: 768px) {
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_braceletsContainerImgArrowMobile__18f99 {
          display: none; } }
    .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_content__WHIYE {
      display: flex;
      justify-content: space-around;
      align-items: center; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_content__WHIYE {
          flex-direction: column;
          align-items: center; } }
    .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_contentVideo__TdxaW {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_contentVideo__TdxaW {
          flex-direction: column-reverse;
          align-items: center; } }
    .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_ownBraceletsTextContainer___c90E {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 600px; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_ownBraceletsTextContainer___c90E {
          padding: 0 20px; } }
      .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_ownBraceletsTextContainer___c90E h1 {
        color: #50a7b4; }
    .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_iframeVideo__qca4b {
      width: 600px;
      height: 350px; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_ownBraceletsContainer__12vDp .Landing_iframeVideo__qca4b {
          width: 85%;
          height: 200px;
          margin-bottom: 20px; } }
  .Landing_Landing__3Atic .Landing_historyContainer__1cu8u {
    display: flex;
    background-color: #efe9d6; }
    @media (max-width: 480px) {
      .Landing_Landing__3Atic .Landing_historyContainer__1cu8u {
        flex-direction: column; } }
    .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyContainerContent__J8tJM {
      max-width: 65%;
      padding: 10px 60px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyContainerContent__J8tJM {
          max-width: 100%;
          padding: 0 20px; } }
      .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyContainerContent__J8tJM h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyContainerContent__J8tJM p {
          max-width: 65%; } }
    .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyImageContainer__2D8t5 {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyImageContainer__2D8t5 {
          margin-right: 15px; } }
      .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyImageContainer__2D8t5 img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .Landing_Landing__3Atic .Landing_historyContainer__1cu8u .Landing_historyImageContainer__2D8t5 img {
            margin-top: 3em;
            margin-bottom: 3em;
            border-radius: 25px; } }
  .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 {
        padding-top: 40px; } }
    .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentContainerContent__84fwZ {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentContainerContent__84fwZ {
          flex-direction: column-reverse; } }
    .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentTextContainer__6XTu9 {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentTextContainer__6XTu9 {
          max-width: 100%; } }
      .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentTextContainer__6XTu9 h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentTextContainer__6XTu9 p {
          max-width: 70%; } }
    .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentImageContainer__3uul2 {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentImageContainer__3uul2 {
          margin-left: 15px; } }
      .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentImageContainer__3uul2 img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .Landing_Landing__3Atic .Landing_enviromentContainer__7TLY5 .Landing_enviromentImageContainer__3uul2 img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .Landing_Landing__3Atic .Landing_sampleContainer__AD05M {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 0.25rem;
    flex-direction: row; }
    @media (max-width: 480px) {
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M {
        flex-direction: column; } }
    .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw {
      display: flex;
      justify-content: center;
      position: relative; }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_sampleImg__3bi8M {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_sampleImg__3bi8M {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_sampleImg__3bi8M {
            height: 500px;
            padding-right: 4em; } }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_sampleImg__3bi8M:hover {
        cursor: pointer; }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_mockStepsView__1hbnT {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_mockStepsView__1hbnT {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_mockStepsView__1hbnT {
            height: 450px;
            margin-right: -2.50em; } }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_mockStepsView__1hbnT:hover {
        cursor: pointer; }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_mockContainerImg__2c6Qg {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_mockContainerImg__2c6Qg {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentMock__3UsNw .Landing_mockContainerImg__2c6Qg {
            left: 0; } }
    .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui {
          margin-right: 20px;
          margin-left: 20px; } }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui h1 {
            padding-left: 66px; } }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui .Landing_content__WHIYE {
        display: flex;
        justify-content: space-around; }
      .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui .Landing_sampleList__2u0Wc {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui .Landing_sampleList__2u0Wc {
            font-size: 17px;
            padding-left: 0; } }
        .Landing_Landing__3Atic .Landing_sampleContainer__AD05M .Landing_contentList__29Rui .Landing_sampleList__2u0Wc li {
          display: flex;
          align-items: center; }
  .Landing_Landing__3Atic .Landing_rateUsContainer__2iSyD {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-bottom: -2px; }
    @media (min-width: 1024px) {
      .Landing_Landing__3Atic .Landing_rateUsContainer__2iSyD {
        padding-top: 3rem;
        padding-bottom: 4rem; } }
  .Landing_Landing__3Atic .Landing_rateUsCopy__3cYLv {
    margin: 2rem; }
  .Landing_Landing__3Atic .Landing_rateIconItem__3JOLo {
    display: flex;
    justify-content: center; }
    .Landing_Landing__3Atic .Landing_rateIconItem__3JOLo .Landing_rateIconButton__14FyI {
      background-color: rgba(255, 255, 255, 0.89); }
    .Landing_Landing__3Atic .Landing_rateIconItem__3JOLo .Landing_rateIcon__19bWI {
      width: 3.5rem; }
  .Landing_Landing__3Atic .Landing_reviewsContainer__--zwF {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 4rem;
    margin-bottom: -2px; }
  .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 {
    margin: 1rem; }
    .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewHead__1hfcY {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff; }
      .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewLogo__lryKS {
        width: 150px;
        height: 150px;
        object-fit: cover;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewLogo__lryKS {
            width: 80px;
            height: 80px; } }
      .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewDataContainer__VE048 {
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewDataContainer__VE048 {
            max-width: 500px;
            margin-top: 20px;
            text-align: justify; } }
        .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewDataContainer__VE048 .Landing_reviewTitle__1GxUp {
          margin-top: 1rem;
          color: #ffffff;
          text-align: center; }
        .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewDataContainer__VE048 .Landing_starsContainer__GmxCo {
          display: flex;
          align-items: center; }
        .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewDataContainer__VE048 a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewDataContainer__VE048 p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_reviewGridItem__2fwk3 .Landing_reviewContainerContent__1LR7b .Landing_reviewDataContainer__VE048 h6 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .Landing_Landing__3Atic .Landing_newsContainer__3uaqo {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%; }
    .Landing_Landing__3Atic .Landing_newsContainer__3uaqo button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      padding-bottom: 3rem;
      min-height: 20rem;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column;
          min-height: 27rem; } }
      .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd .Landing_newsLogo__1BI_k {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd .Landing_newsLogo__1BI_k {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd .Landing_newsDataContainer__2rpnv {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd .Landing_newsDataContainer__2rpnv {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd .Landing_newsDataContainer__2rpnv a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd .Landing_newsDataContainer__2rpnv p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .Landing_Landing__3Atic .Landing_newsContainer__3uaqo .Landing_newsContainerContent__20sbd .Landing_newsDataContainer__2rpnv h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 {
        align-items: center; } }
    .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_sevi__2tRYW {
      max-width: 85%;
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_codeModal__1yW5j {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 {
          flex-direction: column; } }
      .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_footerLawsContainer__1LMIk {
        display: flex;
        margin: 0.5rem; }
        .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_footerLawsContainer__1LMIk p {
          margin: 0.25rem;
          font-size: 15px;
          color: #716b77; }
      .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_seviLogo__2tEqt {
        width: 150px; }
      .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_footerLogosContainer__toA53 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25rem;
        margin-top: 2rem; }
        @media (max-width: 480px) {
          .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_footerLogosContainer__toA53 {
            flex-direction: column; } }
        .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_footerLogosContainer__toA53 img {
          margin: 1rem; }
      .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_footerContentServices__wFdP0 {
        display: flex;
        flex-direction: column; }
        .Landing_Landing__3Atic .Landing_footerContainer__3Rvd4 .Landing_footerContent__2_D-4 .Landing_footerContentServices__wFdP0 div {
          max-width: 400px; }

.Landing_modalInfoHeader__1xBF2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.Landing_modalInfoContent__hDE-v {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .Landing_modalInfoContent__hDE-v {
      flex-direction: column; } }
  .Landing_modalInfoContent__hDE-v li {
    font-size: 20px;
    margin-top: 10px; }
  .Landing_modalInfoContent__hDE-v .Landing_imageHand__n0m4I {
    width: 15rem;
    height: 15rem; }
  .Landing_modalInfoContent__hDE-v .Landing_imageKid__2b5MA {
    width: 11rem;
    height: 22rem; }

.Landing_seeMoreText__2YTXz {
  text-decoration: underline; }

.Landing_cookiesLayer__BJSIS {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .Landing_cookiesLayer__BJSIS .Landing_snackbar__3Pf6X {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .Landing_cookiesLayer__BJSIS .Landing_snackbar__3Pf6X {
        width: 75%;
        padding: 2rem; } }
    .Landing_cookiesLayer__BJSIS .Landing_snackbar__3Pf6X .Landing_moreInfoCookieText__89aPb {
      color: white;
      margin: 5px; }
    .Landing_cookiesLayer__BJSIS .Landing_snackbar__3Pf6X button {
      background-color: transparent;
      color: white;
      border: none; }

.Landing_finalImgContainer__af5fU {
  position: relative;
  margin: 0; }
  .Landing_finalImgContainer__af5fU .Landing_asterisco__1S4oN {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .Landing_finalImgContainer__af5fU .Landing_asterisco__1S4oN {
        right: 41%; } }

.Landing_cookiesMoreInfoButton__2Euvr {
  background-color: #50a7b4 !important; }

.Landing_cookiesMoreInfoButton__2Euvr:hover {
  background-color: #448086 !important; }

.Landing_privacy__2GylD {
  cursor: pointer; }

.Landing_privacy__2GylD:hover {
  text-decoration: underline; }

.Landing_shareButtonsBox__UhHCk {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.Landing_shareButton__3xmNL:hover {
  cursor: pointer; }

.Landing_floatingButtonGetIt__2YUY8, .Landing_floatingButtonGetItLogged__3wFOg {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .Landing_floatingButtonGetIt__2YUY8, .Landing_floatingButtonGetItLogged__3wFOg {
      display: none !important; } }

.Landing_floatingButtonGetItLogged__3wFOg {
  bottom: 5em !important; }

.Landing_floatingButtonGetItIcon__1gi0v {
  margin-right: 0.3em; }

.Landing_companiesTrustContainer__hmVuT {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .Landing_companiesTrustContainer__hmVuT {
      padding-bottom: 5em;
      flex-direction: column; } }
  .Landing_companiesTrustContainer__hmVuT .Landing_companiesTrustContent__3CdOd {
    display: flex;
    align-items: center; }
  .Landing_companiesTrustContainer__hmVuT .Landing_companiesTrustLogoEnisa__3I6Fa {
    width: 150px;
    margin-right: 1.25rem;
    height: auto; }
    @media (max-width: 480px) {
      .Landing_companiesTrustContainer__hmVuT .Landing_companiesTrustLogoEnisa__3I6Fa {
        max-height: 8rem;
        margin-right: 0;
        width: 55px; } }
  .Landing_companiesTrustContainer__hmVuT .Landing_companiesTrustLogo__1NNki {
    width: 190px;
    height: auto; }
    @media (max-width: 480px) {
      .Landing_companiesTrustContainer__hmVuT .Landing_companiesTrustLogo__1NNki {
        max-height: 8rem;
        width: 105px; } }

.Landing_companiesTrustClaim__3TGXs {
  color: #ffffff !important;
  font-size: 80px !important;
  text-align: center !important;
  padding-bottom: 6rem !important;
  margin-top: 1rem !important;
  min-width: 75%;
  max-width: 90%;
  border-bottom: 0.25rem solid #fff; }
  @media (max-width: 480px) {
    .Landing_companiesTrustClaim__3TGXs {
      min-width: 90% !important;
      max-width: 90% !important;
      font-size: 30px !important;
      padding-bottom: 2.25rem !important;
      margin-bottom: 1rem !important; } }

.Landing_floatingActiveBraceletButton__2HwBY {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.Landing_activeIcon___ykqa {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.Landing_FbLikeButtonContainer__1Z3rn {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .Landing_FbLikeButtonContainer__1Z3rn {
      padding-top: 3em; } }

.Landing_facebookLikeButton___qq_E {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .Landing_facebookLikeButton___qq_E {
      display: none !important; } }
  @media (min-width: 768px) {
    .Landing_facebookLikeButton___qq_E {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.Landing_facebookLike__20rw5 {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .Landing_facebookLike__20rw5 {
      height: 45px;
      width: 45px; } }

.Landing_pulsidHeadImage__1WooZ {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .Landing_pulsidHeadImage__1WooZ {
      display: block; } }

.Landing_pulsidPriceBadgeDesktop__3Kh55 {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .Landing_pulsidPriceBadgeDesktop__3Kh55 {
      display: none !important; } }

.Landing_pulsidPriceBadgeAlways__3AmzP {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.Landing_pulsidPriceBadge__3dKrJ {
  display: none !important; }
  @media (max-width: 768px) {
    .Landing_pulsidPriceBadge__3dKrJ {
      display: block !important; } }
  .Landing_pulsidPriceBadge__3dKrJ span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.Landing_discountSticker__1TM0p {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.Landing_offerSnackbar__2aI_6 {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .Landing_offerSnackbar__2aI_6 div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.Landing_snackOfferButton__3rDqQ {
  background-color: #448086;
  color: #fff8e3; }

.Landing_offerBanner__3uICu {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .Landing_offerBanner__3uICu {
      flex-direction: column;
      display: none; } }

.Landing_offerBannerMobile__2gyaU {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .Landing_offerBannerMobile__2gyaU {
      display: flex;
      justify-content: space-around; } }

.Landing_closeOfferBanner__3BVQ9 {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .Landing_closeOfferBanner__3BVQ9 {
      position: absolute;
      right: 5px;
      top: 5px; } }

.Landing_offerBannerCountdown__2s3BE {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .Landing_offerBannerCountdown__2s3BE {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .Landing_offerBannerCountdown__2s3BE, .Landing_offerClaim__20Dgd {
    display: flex;
    flex-direction: column; } }

.Landing_offerClaim__20Dgd span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .Landing_offerClaim__20Dgd span:first-child {
      display: inline-flex !important; } }

.Landing_offerButtonBox__26VOo {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .Landing_offerButtonBox__26VOo {
      margin-top: 1em !important; } }

.Landing_offerBannerGetItButton__35nvL {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.Landing_closeOfferBannerIcon__1RWoC {
  color: #ffffff; }

.Landing_whatsappPostSection__1aqsI {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.Landing_whatsappPostClaim__2vXFo {
  text-align: center !important; }

.Landing_whatsappPostItem__1PQ_S {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .Landing_whatsappPostItem__1PQ_S .Landing_downloadDocumentButton__2azxw {
    background-color: #fff8e3; }

.Landing_whatsappPostImage__3Ok-C {
  height: 250px; }

.Landing_newsletterSectionGrid__yHBbX {
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: -4px;
  margin-bottom: -4px;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .Landing_newsletterSectionGrid__yHBbX {
      background-color: #4FA9B3;
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.Landing_freeShippingClaim__19taB {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Roboto Bold', sans-serif;
  padding-left: 0.4rem;
  padding-right: 1.2rem; }
  .Landing_freeShippingClaim__19taB .Landing_countDown__3Hbz_ {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 17px; }
    @media (min-width: 768px) {
      .Landing_freeShippingClaim__19taB .Landing_countDown__3Hbz_ {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 30px; } }
  .Landing_freeShippingClaim__19taB span {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 20px; }
    @media (min-width: 768px) {
      .Landing_freeShippingClaim__19taB span {
        font-size: 30px; } }

.Landing_CTAContainer__2figb {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em !important; }
  .Landing_CTAContainer__2figb .Landing_tapIcon__3NMxh {
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: rotate(-25deg); }
  .Landing_CTAContainer__2figb .Landing_primaryCTAButton__3mcvG {
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .Landing_CTAContainer__2figb .Landing_primaryCTAButton__3mcvG {
        font-size: 20px; } }
  .Landing_CTAContainer__2figb .Landing_primaryCTAButton__3mcvG:hover {
    background-color: #ea605d; }
  .Landing_CTAContainer__2figb .Landing_secondaryCTAButton__sulNE {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .Landing_CTAContainer__2figb .Landing_secondaryCTAButton__sulNE {
        font-size: 20px !important; } }

.Landing_buyedClaimContainer__1vtHI {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem !important; }
  @media (min-width: 1024px) {
    .Landing_buyedClaimContainer__1vtHI {
      margin-bottom: 1.25rem !important; } }

.Landing_buyedClaimBox__3omZt, .Landing_buyNowClaimBox__3FCj0, .Landing_womenDayBanner__2-6Be {
  width: 100%;
  height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  font-size: 17px;
  text-align: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .Landing_buyedClaimBox__3omZt, .Landing_buyNowClaimBox__3FCj0, .Landing_womenDayBanner__2-6Be {
      font-size: 20px;
      margin-right: -1rem;
      margin-left: -1rem; } }

.Landing_buyNowClaimBox__3FCj0 {
  background-color: #FF6966; }

.Landing_womenDayBanner__2-6Be {
  background-color: #d228ac; }

.Landing_countUp__2Os09 {
  margin-top: 3rem !important;
  color: #fff;
  font-size: 70px !important;
  text-align: center; }
  @media (min-width: 768px) {
    .Landing_countUp__2Os09 {
      margin-top: 6rem !important; } }

.Landing_footerVideo__2PXpI {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem; }
  @media (min-width: 1024px) {
    .Landing_footerVideo__2PXpI {
      width: 50%;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.13); } }

.Landing_navidadCollage__3dnuJ {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .Landing_navidadCollage__3dnuJ {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.Snack_snackParentWrapper__1QVHT {
  z-index: 300000 !important; }

.Snack_success__2SiPg {
  z-index: 200000 !important; }
  .Snack_success__2SiPg div {
    background-color: #4fb457;
    font-weight: bold; }

.Snack_warning__2jog9 {
  z-index: 200000 !important; }
  .Snack_warning__2jog9 div {
    background-color: #ffac00;
    font-weight: bold; }

.Snack_error__3y38I {
  z-index: 200000 !important; }
  .Snack_error__3y38I div {
    background-color: #dd2c01;
    font-weight: bold; }

.Snack_snackButton__2gxSY {
  z-index: 200000 !important;
  color: #fff !important;
  border-color: #fff !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@media (min-width: 1024px) {
  .BuyModals_mainDialogContent__3I2nr {
    border-radius: 8px;
    margin: 0 8rem;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; } }

.BuyModals_parentContainer__2m0QM {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: inherit !important; }

.BuyModals_termsContainer__2s61i {
  display: flex;
  flex-direction: column;
  align-items: center; }

.BuyModals_formControl__DT4AJ {
  margin-top: 8px;
  min-width: 120px;
  display: flex !important; }
  .BuyModals_formControl__DT4AJ label {
    color: #50a7b4 !important; }
  .BuyModals_formControl__DT4AJ div fieldset {
    border-color: #50a7b4 !important; }
  .BuyModals_formControl__DT4AJ svg {
    color: #50a7b4 !important; }

.BuyModals_checkbox__3ZEyC {
  color: #50a7b4 !important; }

.BuyModals_countryGridItem__21ccs {
  visibility: hidden; }

.BuyModals_formRedsys__2n37h {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem; }

.BuyModals_cityZipcodeContainer__7TV85 {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.BuyModals_privacyLink__U4VBx {
  color: #716b77; }

.BuyModals_privacyLink__U4VBx:hover {
  color: #0077b5;
  text-decoration: underline; }

.BuyModals_closeModalIcon__6RdnT {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BuyModals_fixModalPadding__3pZk0 {
  padding: 0 !important; }

.BuyModals_paymentModal__1HmFh {
  padding: 0 !important; }
  .BuyModals_paymentModal__1HmFh [class^="MuiPaper-root"] {
    min-width: 350px; }
  .BuyModals_paymentModal__1HmFh .BuyModals_paymentModalTitleBox__2Sdfy {
    display: flex;
    align-items: center;
    margin-top: -20px; }

.BuyModals_sendingDataAnimation__3Ylpt {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BuyModals_textField__3YaWz label {
  color: #50a7b4 !important; }

.BuyModals_textField__3YaWz div fieldset {
  border-color: #50a7b4 !important; }

.BuyModals_textFieldError__9ouzu label {
  color: #ff3d00 !important; }

.BuyModals_textFieldError__9ouzu div fieldset {
  border-color: #ff3d00 !important; }

.BuyModals_contentText__1p1CD {
  margin-bottom: 10px !important; }

.BuyModals_contentInnerText__2EdLX {
  margin-left: 8px !important;
  font-size: 2.2rem !important;
  align-self: start !important; }

.BuyModals_formContainer__1drTg {
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.BuyModals_sizeCardContainer__10_Xw {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-around; }
  @media (max-width: 480px) {
    .BuyModals_sizeCardContainer__10_Xw {
      flex-direction: column-reverse;
      margin-top: 10px !important; } }
  .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: none; }
    @media (max-width: 480px) {
      .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE {
        margin: 1.00em;
        padding: 1em; } }
    .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_pictureContainer__2r3qQ {
      display: flex;
      justify-content: center; }
    .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizeCardImg__14-qr {
      width: 250px;
      margin: 1em !important;
      border-radius: 4px; }
      @media (max-width: 480px) {
        .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizeCardImg__14-qr {
          width: 190px; } }
    .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizeCardSize__2qiRl {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #448086; }
      .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizeCardSize__2qiRl .BuyModals_braceletSizeBox__3PIwd {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #448086;
        margin-top: 5px;
        margin-bottom: 10px; }
      .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizeCardSize__2qiRl .BuyModals_braceletSizeCode__2fMaz {
        margin-top: 10px; }
      .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizeCardSize__2qiRl svg {
        font-size: 15px; }
    .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE p {
      color: #448086;
      font-weight: bold; }
    .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizePicker__2yfUJ {
      margin: 1em; }
      .BuyModals_sizeCardContainer__10_Xw .BuyModals_sizeCard__1xdqE .BuyModals_sizePicker__2yfUJ label {
        font-size: 15px;
        margin-left: -3px; }
  .BuyModals_sizeCardContainer__10_Xw .BuyModals_braceletsSelectorGrid__3hj-_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    @media (max-width: 480px) {
      .BuyModals_sizeCardContainer__10_Xw .BuyModals_braceletsSelectorGrid__3hj-_ {
        flex-direction: column-reverse; } }

.BuyModals_sizeSelectorActions__11Vwr {
  display: flex; }

@media (min-width: 768px) {
  .BuyModals_sizeSelectorMobileButton__3SXtT {
    display: none !important; } }

.BuyModals_sizeStock__LwWZ1 {
  color: red !important;
  margin: 0;
  padding-bottom: 0.5rem; }

.BuyModals_sizeCardContainerCart__1piPe {
  justify-content: center;
  margin-bottom: 1em !important;
  flex-direction: column-reverse;
  align-items: flex-end; }
  .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-left: solid 5px #50a7b4; }
    @media (min-width: 480px) {
      .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 {
        padding: 0 1rem;
        margin: 1rem; } }
    @media (max-width: 480px) {
      .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 {
        width: 100% !important; } }
    .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 .BuyModals_sizeCardImg__14-qr {
      width: 100px;
      margin: 1em !important; }
      @media (max-width: 480px) {
        .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 .BuyModals_sizeCardImg__14-qr {
          width: 90px; } }
    .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 p, .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 span {
      color: #448086;
      font-weight: bold;
      margin: 5px; }
    .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 span {
      margin-top: 5px; }
    .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 .BuyModals_productImageBox__1GQ03 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .BuyModals_sizeCardContainerCart__1piPe .BuyModals_sizeCardCart__2nHk8 .BuyModals_productDetails__19Dkv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }

.BuyModals_cartItem__1k37f {
  display: flex !important;
  justify-content: center;
  padding: 10px 0 !important; }

.BuyModals_discountContainer__3FQf6 {
  display: flex;
  align-items: center; }
  .BuyModals_discountContainer__3FQf6 .BuyModals_discountButton__3uNHl {
    margin-left: 10px;
    border: 1px solid #50a7b4;
    color: #50a7b4 !important; }
  .BuyModals_discountContainer__3FQf6 .BuyModals_discountButton__3uNHl:disabled {
    border: 1px solid #aaaaaa;
    color: #e0e0e0 !important; }

.BuyModals_snackTotalBraceletsMessage__21dSP {
  font-weight: bold; }

.BuyModals_snackTotalBracelets__krTY9 {
  padding: 15px; }
  .BuyModals_snackTotalBracelets__krTY9 button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer; }
  .BuyModals_snackTotalBracelets__krTY9 span {
    background-color: darkorange; }
  .BuyModals_snackTotalBracelets__krTY9 a {
    color: #fff; }
  .BuyModals_snackTotalBracelets__krTY9 a:hover {
    color: blue; }

.BuyModals_packagingSection__3qvZf {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.BuyModals_packagingCheck__1d1o4 {
  align-self: flex-start !important; }

.BuyModals_packagingCheckLabel__wxt-e {
  color: #716b77 !important; }

.BuyModals_packagingQuantitySelector__2CZm1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  padding: 5px; }
  @media (max-width: 480px) {
    .BuyModals_packagingQuantitySelector__2CZm1 {
      width: 75%; } }

.BuyModals_packagingImgContainer__2b98O {
  display: flex;
  align-items: center;
  justify-content: center; }

.BuyModals_packagingImg__2po-9 {
  width: 270px;
  height: auto;
  border-radius: 15px;
  margin: 1em !important; }
  @media (max-width: 480px) {
    .BuyModals_packagingImg__2po-9 {
      height: auto;
      width: 220px; } }

.BuyModals_packagingInput__3MnDm {
  width: 100px; }

.BuyModals_packagingCartImg__36dRu {
  margin-top: 0.5em;
  width: 100%;
  border-radius: 15px; }
  @media (max-width: 480px) {
    .BuyModals_packagingCartImg__36dRu {
      width: 150px; } }

.BuyModals_priceChip__4A6P0 {
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 1.5rem !important; }

.BuyModals_totalPriceChip__3dU0B {
  padding: 0.5rem !important;
  font-size: 1rem !important;
  color: #ffffff !important;
  background-color: #50a7b4 !important;
  margin-left: 1em; }

.BuyModals_oldPriceChip__1dcQK {
  margin-bottom: 1em;
  margin-top: 0.5em;
  text-decoration: line-through !important; }

.BuyModals_totalPriceWrapper__1HD3x {
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 0.5rem;
  flex-direction: column; }

.BuyModals_parentShippingPriceBox__1b46w {
  display: flex;
  justify-content: center;
  align-items: center; }

.BuyModals_shippingPriceBox__2JA3_ {
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-left: 5px solid #CCBD88;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .BuyModals_shippingPriceBox__2JA3_ h6 {
    text-align: center; }
  .BuyModals_shippingPriceBox__2JA3_ .BuyModals_centerContainer__1ptPl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }

.BuyModals_offerCard__3obkC {
  margin: auto;
  width: 75%;
  flex-direction: column;
  padding: 1em !important;
  text-align: center !important;
  border-radius: 50px !important;
  margin-bottom: 0.5em !important;
  margin-top: 0.5em !important;
  background-image: linear-gradient(-20deg, #fdbeb0 0%, #fdf8bd 100%); }
  @media (min-width: 768px) {
    .BuyModals_offerCard__3obkC {
      width: 25%; } }

.BuyModals_dialogActions__bYzKo, .BuyModals_dialogActionsFirstStep__2V3y2 {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding: 0.5em;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 0 !important;
  z-index: 300;
  text-align: center; }

.BuyModals_dialogActionButton__1c3FT {
  padding: 0.65em 3em !important;
  margin: 5px !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bolder !important;
  background-color: #8BC34A !important; }

.BuyModals_dialogActionButton__1c3FT:disabled {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: initial !important;
  color: #808080 !important; }

.BuyModals_disabledButton__1ZlTl {
  padding: 0.65em 3em !important;
  margin: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  color: #808080 !important;
  box-shadow: none !important;
  font-size: 20px !important;
  font-weight: bolder !important;
  cursor: default !important; }

.BuyModals_loadingPaymentActionsModal__1m4yy [class^="MuiPaper-root"] {
  transition: height .45s ease-in-out !important; }

.BuyModals_redsysAnimationContainer__HG4AG {
  transition: opacity .45s ease-in-out !important;
  opacity: 0;
  text-align: center; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .BuyModals_cityZipcodeContainer__7TV85 {
    display: flex;
    background-color: #4fb457;
    flex-direction: column;
    justify-content: space-between; } }

.BuyModals_cartResumeListTitle__yrhay {
  color: #000000 !important;
  font-size: initial !important;
  font-weight: initial !important; }

.BuyModals_discountPrimaryText__2a0cn {
  color: #ff7428 !important; }

.BuyModals_discountSecondaryText__2moya {
  color: #ff7428 !important; }

.BuyModals_hurrify1__1lmAb, .BuyModals_hurrify2__2Iuxf {
  padding: 1rem 1rem;
  text-align: center !important;
  margin: 1rem; }

.BuyModals_hurrify1__1lmAb {
  background-color: #e57373;
  color: #ffffff; }

.BuyModals_hurrify2__2Iuxf {
  background-color: #ffd04dc7;
  border-radius: 4rem; }

.BuyModals_hurrify3__2sI3g {
  text-align: center !important;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

.BuyModals_securePaymentBox__3K9dS {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem; }

.BuyModals_securePaymentItem__3aiKs {
  display: flex;
  justify-content: center; }

.BuyModals_securePaymentClaimLogo__2aPy4 {
  width: 12rem; }

.BuyModals_securePaymentLogo__2eomA {
  width: 5rem; }

.BuyModals_shippingPriceSelector__34IZP {
  margin-bottom: 1rem; }

.BuyModals_freeShippingOptionLabel__3FjY1 {
  font-size: 17px;
  margin-top: 2rem !important;
  background-color: rgba(87, 255, 128, 0.6);
  border-radius: 4px;
  padding: 5px; }

.BuyModals_productImgCarousel__2Ec4g {
  margin-top: 1rem; }
  .BuyModals_productImgCarousel__2Ec4g img {
    border-radius: 4px; }

/*font-family*/
/*font-size*/
/*font-weight*/
.LoginModal_formControl__2Gofl {
  margin-top: 8px;
  min-width: 120px; }

.LoginModal_flexColumn__2ZRVn {
  display: flex;
  flex-direction: column; }
  .LoginModal_flexColumn__2ZRVn button {
    background-color: #50a7b4;
    color: white; }
  .LoginModal_flexColumn__2ZRVn button:hover {
    background-color: #448086;
    color: white; }

.LoginModal_noEqualPasswordsMessage__1qn9L {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.LoginModal_noEqualPasswordsMessageBox__2mhaP {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.LoginModal_paymentModalTitleBox__ywJvr {
  display: flex;
  align-items: center; }

.LoginModal_sendingDataAnimation__2XYzK {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.LoginModal_closeModalIcon__1A15C {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.LoginModal_forgottenPasswordText__7pxdX {
  margin-top: 10px !important;
  font-style: italic; }

.LoginModal_textField__1GsG1 label {
  color: #50a7b4 !important; }

.LoginModal_textField__1GsG1 div {
  padding-right: 0 !important; }
  .LoginModal_textField__1GsG1 div fieldset {
    border-color: #50a7b4 !important; }

.LoginModal_textField__1GsG1 button {
  color: #50a7b4;
  background-color: transparent; }

.LoginModal_textField__1GsG1 button:hover {
  background-color: transparent;
  color: #448086; }

/*font-family*/
/*font-size*/
/*font-weight*/
.Navbar_navbar__1RAPY {
  width: 100%;
  position: fixed;
  z-index: 70;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #4FA9B3;
  background-color: transparent; }
  .Navbar_navbar__1RAPY .Navbar_logoImg__3QU0S {
    width: 75px;
    cursor: pointer;
    margin-left: 0.2rem; }
    @media (min-width: 768px) {
      .Navbar_navbar__1RAPY .Navbar_logoImg__3QU0S {
        margin-left: 2.5%;
        width: 100px; } }
  .Navbar_navbar__1RAPY .Navbar_buttonsContainer__9RDMs {
    display: flex;
    align-items: center; }
    .Navbar_navbar__1RAPY .Navbar_buttonsContainer__9RDMs .Navbar_buttonsContent__2hcZL {
      display: flex;
      flex-direction: row; }
      @media (max-width: 767px) {
        .Navbar_navbar__1RAPY .Navbar_buttonsContainer__9RDMs .Navbar_buttonsContent__2hcZL {
          display: none; } }
      @media (max-width: 768px) {
        .Navbar_navbar__1RAPY .Navbar_buttonsContainer__9RDMs .Navbar_buttonsContent__2hcZL {
          display: none;
          font-size: 17px; } }
    .Navbar_navbar__1RAPY .Navbar_buttonsContainer__9RDMs .Navbar_actionsContent__39zkU {
      display: flex;
      flex-direction: row;
      align-items: center; }
      @media (max-width: 340px) {
        .Navbar_navbar__1RAPY .Navbar_buttonsContainer__9RDMs .Navbar_actionsContent__39zkU {
          flex-direction: column; } }
      @media (max-width: 768px) {
        .Navbar_navbar__1RAPY .Navbar_buttonsContainer__9RDMs .Navbar_actionsContent__39zkU button {
          font-size: 17px; } }

.Navbar_menuButton__1KDfa {
  color: #ffffff !important;
  margin-right: 0.5rem !important; }
  @media (min-width: 768px) {
    .Navbar_menuButton__1KDfa {
      display: flex !important; } }
  @media (min-width: 1024px) {
    .Navbar_menuButton__1KDfa {
      display: none !important; } }

.Navbar_nestedList__2eJGh {
  padding-left: 1.8rem !important; }

.Navbar_actionButton__2MVk4 {
  background-color: #ffffff !important;
  color: #000 !important;
  margin: 20px 10px !important;
  cursor: pointer;
  font-size: 20px; }
  @media (max-width: 768px) {
    .Navbar_actionButton__2MVk4 {
      font-size: 17px;
      min-width: auto !important;
      display: none !important; } }

.Navbar_actionButtonOutlined__2RAyF {
  border-color: #ffffff !important;
  color: #ffffff !important;
  margin: 20px 10px !important;
  cursor: pointer;
  font-size: 20px; }
  @media (max-width: 768px) {
    .Navbar_actionButtonOutlined__2RAyF {
      font-size: 17px;
      min-width: auto !important;
      display: none !important; } }

.Navbar_actionButtonGetIt__Im6Ot {
  color: #ffffff !important;
  margin: 20px 10px !important;
  font-weight: bold !important; }
  @media (min-width: 768px) {
    .Navbar_actionButtonGetIt__Im6Ot {
      font-size: 17px;
      display: none !important; } }
  @media (max-width: 480px) {
    .Navbar_actionButtonGetIt__Im6Ot {
      display: none !important; } }

.Navbar_landingButton__2CjgC {
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;
  margin: 20px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer; }

.Navbar_logoutModal__1bqRn .Navbar_contentModal__1YmG2 {
  min-width: 350px; }
  @media (max-width: 768px) {
    .Navbar_logoutModal__1bqRn .Navbar_contentModal__1YmG2 {
      min-width: 100px; } }

.Navbar_hiddenScrollToTopLink__3xrJ6 {
  display: none; }

.Navbar_activeBraceletButton__1Qy4B {
  color: #fff8e3 !important; }
  @media (max-width: 768px) {
    .Navbar_activeBraceletButton__1Qy4B {
      display: none !important; } }

.Navbar_offerBanner__tCWGi {
  width: 100%;
  padding: 3em;
  text-align: center;
  font-size: 17px;
  background-color: black;
  color: white; }

.Navbar_spanishProductIcon__2JFQM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto; }
  .Navbar_spanishProductIcon__2JFQM span {
    color: #fff;
    font-size: 12px; }

/*font-family*/
/*font-size*/
/*font-weight*/
.BeforeCloseAlertModal_dialogContentText__2XjD0 {
  font-size: 20px !important;
  margin-bottom: 1em !important;
  text-align: center; }

.BeforeCloseAlertModal_mainActionButton__Za3UU {
  border-radius: 25px !important;
  padding: 1rem 3rem !important; }

.BeforeCloseAlertModal_closeModalIcon__1x18z {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BeforeCloseAlertModal_dialogActions__3hbX3 {
  justify-content: center !important; }

.BeforeCloseAlertModal_sendingDataAnimation__3fUWn {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterModal_parentContainer__1a6NC {
  display: flex;
  justify-content: center; }

.NewsletterModal_dialogContentText__3iUhj {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important; }

.NewsletterModal_mainActionButtonContainer__1pecD {
  display: flex;
  justify-content: center; }

.NewsletterModal_mainActionButton__3BJgu {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.NewsletterModal_dialogActions__10qrm {
  justify-content: center !important; }

.NewsletterModal_closeModalIcon__8Wnd1 {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterModal_sendingDataAnimation__3JFOr {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PresaleModal_parentContainer__1OYgI {
  display: flex;
  justify-content: center; }

.PresaleModal_dialogContentText__VkKbH {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.PresaleModal_mainActionButtonContainer__oM9fV {
  display: flex;
  justify-content: center; }

.PresaleModal_mainActionButton__3uP2p {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.PresaleModal_dialogActions__nMs_9 {
  justify-content: center !important; }

.PresaleModal_closeModalIcon__wiqwR {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.PresaleModal_sendingDataAnimation__34O1s {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterSection_parentContainer__1Cko6 {
  display: flex;
  justify-content: center; }

.NewsletterSection_dialogContentText__1sdX3 {
  font-size: 20px !important;
  text-align: center !important;
  margin-bottom: 1em !important; }

.NewsletterSection_mainActionButtonContainer__1bAdl {
  display: flex;
  justify-content: center; }

.NewsletterSection_mainActionButton__25GWq {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }
  @media (min-width: 768px) {
    .NewsletterSection_mainActionButton__25GWq {
      margin-left: 2rem !important; } }

.NewsletterSection_dialogActions__2qTP0 {
  justify-content: center !important; }

.NewsletterSection_closeModalIcon__1-kLd {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterSection_sendingDataAnimation__2AkyF {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

@media (max-width: 768px) {
  .NewsletterSection_card__2nSJW {
    box-shadow: none !important; } }

@media (min-width: 768px) {
  .NewsletterSection_cardContent__1Iwvn {
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 768px) {
  .NewsletterSection_inputAndClaimParentContainer__3ZmOI {
    margin-right: 2rem;
    margin-left: 2rem; } }

.NewsletterSection_inputAndButtonContainer__1NSrv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .NewsletterSection_inputAndButtonContainer__1NSrv {
      flex-direction: row; } }

.NewsletterSection_succeedAnimationContainer__2G43X {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .NewsletterSection_succeedAnimationContainer__2G43X .NewsletterSection_succeedAnimationClaim__2TqyJ {
    margin-top: 1rem; }

/*font-family*/
/*font-size*/
/*font-weight*/
.Privacy_privacy__sLP_q {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding: 4% 20% 4% 20%;
  text-align: justify; }
  @media (max-width: 768px) {
    .Privacy_privacy__sLP_q {
      padding: 4% 20px 4% 20px; } }
  .Privacy_privacy__sLP_q h2 {
    color: #000; }

.Privacy_downloadButtons__1I4gD {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2em; }

.Privacy_navBar__d7XLO {
  background-color: #50a7b4 !important; }

.Privacy_privacy__sLP_q {
  cursor: pointer; }

/*font-family*/
/*font-size*/
/*font-weight*/
.Detail_detail__2NIx2 {
  background-color: #fff8e3;
  min-height: 100vh !important; }
  .Detail_detail__2NIx2 .Detail_chargingContent__1dfjM {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .Detail_detail__2NIx2 .Detail_content__1nE1O, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm {
    padding-top: 90px;
    min-height: 88vh; }
    @media (max-width: 374px) {
      .Detail_detail__2NIx2 .Detail_content__1nE1O, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm {
        padding-top: 145px; } }
    .Detail_detail__2NIx2 .Detail_content__1nE1O ul, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0; }
      .Detail_detail__2NIx2 .Detail_content__1nE1O ul li, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm ul li {
        display: flex;
        align-items: center;
        padding-left: 10%;
        font-size: 17px; }
        .Detail_detail__2NIx2 .Detail_content__1nE1O ul li p, .Detail_detail__2NIx2 .Detail_content__1nE1O ul li h3, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm ul li p, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm ul li h3 {
          margin-right: 10px; }
    .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_header__1-EvQ, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_header__1-EvQ {
      background-color: #fff8e3;
      padding-left: 5%;
      margin-bottom: -1.5rem !important; }
    .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_basicData__37uC3 .Detail_document__12DZy, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_basicData__37uC3 .Detail_document__12DZy {
      margin-top: 10px; }
      .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentText__11_Oj, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentText__11_Oj {
        flex: inherit; }
      .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentTypeText__3Q0iU, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentTypeText__3Q0iU {
        min-width: 100px; }
      .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentViewPhotos__n76L9, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentViewPhotos__n76L9 {
        font-size: 13px;
        color: #50a7b4;
        font-weight: bold;
        cursor: pointer;
        margin-left: 2em; }
      .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentSecondaryBox__36NUb, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_basicData__37uC3 .Detail_document__12DZy .Detail_documentSecondaryBox__36NUb {
        display: flex; }
    .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_emergencyData__18xyR .Detail_header__1-EvQ, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_emergencyData__18xyR .Detail_header__1-EvQ {
      padding-left: 5%;
      font-size: 15px; }
    .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_emergencyData__18xyR .Detail_contact__2cH2-, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_emergencyData__18xyR .Detail_contact__2cH2- {
      border-bottom: 1px solid grey; }
    .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_emergencyData__18xyR .Detail_emergencyDataRow__2Fh-O, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_emergencyData__18xyR .Detail_emergencyDataRow__2Fh-O {
      margin-top: 10px;
      padding-bottom: 10px; }
    @media (max-width: 768px) {
      .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_medicData__3DFmr .Detail_medicObservationRow__1bAZn, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_medicData__3DFmr .Detail_medicObservationRow__1bAZn {
        align-items: flex-start;
        margin-top: 10px; } }
    @media (min-width: 768px) {
      .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_medicData__3DFmr .Detail_medicObservationRow__1bAZn .Detail_medicObservation__ClL38, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_medicData__3DFmr .Detail_medicObservationRow__1bAZn .Detail_medicObservation__ClL38 {
        display: flex;
        align-items: center; } }
    @media (max-width: 768px) {
      .Detail_detail__2NIx2 .Detail_content__1nE1O .Detail_medicData__3DFmr .Detail_medicObservationRow__1bAZn .Detail_medicObservation__ClL38 h3, .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm .Detail_medicData__3DFmr .Detail_medicObservationRow__1bAZn .Detail_medicObservation__ClL38 h3 {
        margin-top: 0; } }
  .Detail_detail__2NIx2 .Detail_noNavBarContent__1unrm {
    padding-top: 10px; }

.Detail_detailBox__2aJJi {
  padding-bottom: 14rem; }
  @media (max-width: 768px) {
    .Detail_detailBox__2aJJi {
      padding-bottom: 16rem; } }

.Detail_errorMessage__1-zoA {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  justify-content: center; }

.Detail_relationshipText__1Kf-U {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.Detail_snack__1Wf_a {
  z-index: 200000 !important; }
  .Detail_snack__1Wf_a div {
    background-color: #ffac00 !important;
    font-weight: bold; }

.Detail_snackMessage__12ahz {
  display: flex;
  align-items: center; }

.Detail_snackIcon___zA6T {
  margin-right: 25px; }

.Detail_snackButton__1Zigk {
  color: #fff !important;
  border-color: #fff !important;
  margin-bottom: 5px !important; }

.Detail_closeModalIcon__3biae {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.Detail_dialogTitle__1t0DO {
  text-align: center; }

.Detail_seeDataButton__3Ez6n {
  background-color: #50a7b4 !important;
  color: #fff8e3 !important;
  padding: 1.5em !important; }

.Detail_stepsContainer__2C243 {
  background-color: #fff8e3;
  border-radius: 25px;
  margin: 1em;
  padding-top: 1em !important; }

.Detail_patientPhotoContainer__3LBme {
  display: flex !important;
  justify-content: center !important;
  padding-left: 0 !important; }

.Detail_patientPhoto__2ZDjz {
  width: 10rem !important;
  height: 10rem !important; }

.Detail_covidCertificatesContainer__2rOSA {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1rem;
  bottom: 1rem; }
  @media (max-width: 768px) {
    .Detail_covidCertificatesContainer__2rOSA {
      width: 100%;
      right: unset;
      align-items: center; } }

.Detail_covidCertificateButton__3getu {
  margin-top: 1rem !important;
  background-color: #4fa9b3 !important;
  color: #fff !important; }
  .Detail_covidCertificateButton__3getu svg {
    color: #fff;
    fill: #fff; }

/*font-family*/
/*font-size*/
/*font-weight*/
.AssignModal_formControl__WAX-J {
  margin-top: 8px;
  min-width: 120px; }

.AssignModal_flexColumn__SgOgu {
  display: flex;
  flex-direction: column; }
  .AssignModal_flexColumn__SgOgu button {
    background-color: #50a7b4;
    color: white; }
  .AssignModal_flexColumn__SgOgu button:hover {
    background-color: #448086;
    color: white; }

.AssignModal_checkbox__1zfwX {
  color: #50a7b4 !important; }

.AssignModal_noEqualPasswordsMessage__3lTYh {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.AssignModal_noEqualPasswordsMessageBox__2JWyg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.AssignModal_paymentModalTitleBox__KUtrm {
  display: flex;
  align-items: center; }

.AssignModal_privacyLink__3_ACR {
  color: #716b77; }

.AssignModal_privacyLink__3_ACR:hover {
  color: #0077b5;
  text-decoration: underline; }

.AssignModal_sendingDataAnimation__Xco_3 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.AssignModal_closeModalIcon__1DBqu {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.AssignModal_forgottenPasswordText__1hMgj {
  margin-top: 10px !important;
  font-style: italic; }

.AssignModal_textField__4pxLa label {
  color: #50a7b4 !important; }

.AssignModal_textField__4pxLa div fieldset {
  border-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PatientModal_formControl__WpGTs {
  margin-top: 8px;
  min-width: 120px; }
  .PatientModal_formControl__WpGTs label {
    color: #50a7b4 !important; }
  .PatientModal_formControl__WpGTs div fieldset {
    border-color: #50a7b4 !important; }
  .PatientModal_formControl__WpGTs svg {
    color: #50a7b4 !important; }

.PatientModal_flexColumn__3-9DU {
  display: flex;
  flex-direction: column; }
  .PatientModal_flexColumn__3-9DU button {
    background-color: #50a7b4;
    color: white; }
  .PatientModal_flexColumn__3-9DU button:hover {
    background-color: #448086;
    color: white; }
  .PatientModal_flexColumn__3-9DU button:disabled {
    background-color: white;
    color: #50a7b4; }

.PatientModal_noEqualPasswordsMessage__2SsbZ {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.PatientModal_noEqualPasswordsMessageBox__1q_PJ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.PatientModal_paymentModalTitleBox__2QkRu {
  display: flex;
  align-items: center; }

.PatientModal_sendingDataAnimation__1L-vU {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.PatientModal_textField__W8Fpj label {
  color: #50a7b4 !important; }

.PatientModal_textField__W8Fpj div fieldset {
  border-color: #50a7b4 !important; }

.PatientModal_contentText__nMn6B {
  border-bottom: 10px; }

.PatientModal_dialogContainer__2opcj [class^="MuiPaper-root"] {
  min-height: 300px; }

.PatientModal_closeModalIcon__1r_pu {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.BasicDataForm_gridContainer__KvOy8 {
  justify-content: space-between !important;
  margin-top: 15px !important;
  margin-bottom: 50px !important; }

.BasicDataForm_photoLabel__2C7fC {
  color: #50a7b4; }

.BasicDataForm_filepondWrapper__jpev9 {
  margin-top: 0.5rem;
  margin-bottom: -1rem; }

.BasicDataForm_formControl__aIw1N, .BasicDataForm_formControlCalendar__DXiuX {
  box-sizing: border-box;
  min-width: 120px;
  display: flex !important; }
  .BasicDataForm_formControl__aIw1N label, .BasicDataForm_formControlCalendar__DXiuX label {
    color: #50a7b4 !important; }
  .BasicDataForm_formControl__aIw1N div, .BasicDataForm_formControlCalendar__DXiuX div {
    box-sizing: border-box; }
    .BasicDataForm_formControl__aIw1N div fieldset, .BasicDataForm_formControlCalendar__DXiuX div fieldset {
      box-sizing: border-box;
      border-color: #50a7b4 !important; }
  .BasicDataForm_formControl__aIw1N svg, .BasicDataForm_formControlCalendar__DXiuX svg {
    color: #50a7b4 !important; }

.BasicDataForm_stepper__1Ljqf {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .BasicDataForm_stepper__1Ljqf {
      position: fixed; } }

.BasicDataForm_flexColumn__3EfeK {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .BasicDataForm_flexColumn__3EfeK button {
    background-color: #50a7b4;
    color: white; }
  .BasicDataForm_flexColumn__3EfeK button:hover {
    background-color: #448086;
    color: white; }
  .BasicDataForm_flexColumn__3EfeK button:disabled {
    background-color: white;
    color: #4FA9B3; }

.BasicDataForm_noEqualPasswordsMessage__m8GNd {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.BasicDataForm_noEqualPasswordsMessageBox__CCEVN {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.BasicDataForm_paymentModalTitleBox__3J-C2 {
  display: flex;
  align-items: center; }

.BasicDataForm_sendingDataAnimation__1tPCN {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BasicDataForm_textField__2bnKA label {
  color: #50a7b4 !important; }

.BasicDataForm_textField__2bnKA div fieldset {
  border-color: #50a7b4 !important; }

.BasicDataForm_textFieldError__fSu6Z label {
  color: #ff3d00 !important; }

.BasicDataForm_textFieldError__fSu6Z div fieldset {
  border-color: #ff3d00 !important; }

.BasicDataForm_dateTextField__2YGpc {
  display: flex !important;
  margin-bottom: 20px !important; }
  .BasicDataForm_dateTextField__2YGpc label {
    color: #50a7b4 !important; }
  .BasicDataForm_dateTextField__2YGpc div fieldset {
    border-color: #50a7b4 !important; }
  .BasicDataForm_dateTextField__2YGpc .BasicDataForm_MuiOutlinedInput-input-289__2-dNj {
    cursor: pointer !important; }
  .BasicDataForm_dateTextField__2YGpc .BasicDataForm_MuiOutlinedInput-input-289__2-dNj:hover {
    cursor: pointer !important; }

.BasicDataForm_dateTextField__2YGpc:hover {
  cursor: pointer !important; }

.BasicDataForm_dateTextFieldError__lED3Y {
  display: flex !important;
  margin-bottom: 5px; }
  .BasicDataForm_dateTextFieldError__lED3Y label {
    color: #ff3d00 !important; }
  .BasicDataForm_dateTextFieldError__lED3Y div fieldset {
    border-color: #ff3d00 !important; }

.BasicDataForm_dateTextFieldError__lED3Y:hover {
  cursor: pointer !important; }

.BasicDataForm_adornmentHover__uOF-r:hover {
  cursor: pointer !important; }

.BasicDataForm_contentText__3xpsj {
  border-bottom: 10px; }

.BasicDataForm_formContainer__2hajD {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.BasicDataForm_doubleColumnContainer__3PXpL {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.BasicDataForm_calendarBox__3oynH {
  display: flex;
  flex-direction: column;
  align-items: center; }

.BasicDataForm_calendar__14IT4 {
  width: 100% !important;
  margin-top: -3px;
  z-index: 36;
  border-top: none !important;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px; }

.BasicDataForm_formControlCalendar__DXiuX input:hover {
  cursor: pointer; }

.BasicDataForm_formControlCalendar__DXiuX:hover {
  cursor: pointer; }

.BasicDataForm_formControlCalendarOpened__1cPjj {
  box-sizing: border-box;
  min-width: 120px;
  display: flex !important; }
  .BasicDataForm_formControlCalendarOpened__1cPjj label {
    color: #50a7b4 !important; }
  .BasicDataForm_formControlCalendarOpened__1cPjj div {
    box-sizing: border-box; }
    .BasicDataForm_formControlCalendarOpened__1cPjj div fieldset {
      box-sizing: border-box;
      border-color: #50a7b4 !important;
      border-bottom: none; }
  .BasicDataForm_formControlCalendarOpened__1cPjj svg {
    color: #50a7b4 !important; }

.BasicDataForm_tutoWrapper__2Gnqd {
  display: flex;
  flex-direction: column;
  text-align: center; }

.BasicDataForm_tutoImgWrapper__3WZwo {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .BasicDataForm_tutoParentWrapper__3cn4V:after, .BasicDataForm_tutoParentWrapper__3cn4V:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .BasicDataForm_tutoParentWrapper__3cn4V:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .BasicDataForm_tutoParentWrapper__3cn4V:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .BasicDataForm_UxTutoMask__1iBoR {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

.BasicDataForm_patientPhotoContainer__19U6N {
  position: relative;
  display: flex;
  justify-content: center; }

.BasicDataForm_patientPhotoWrapper__2mUA3 {
  position: relative; }

.BasicDataForm_patientPhoto__3H7XS {
  height: 6rem !important;
  width: 6rem !important; }

.BasicDataForm_editPhotoButton__gRa69 {
  padding: 0.25rem !important;
  background-color: #4FA9B3 !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important; }
  .BasicDataForm_editPhotoButton__gRa69 svg {
    color: #fff; }

/*font-family*/
/*font-size*/
/*font-weight*/
.MedicalDataForm_gridContainer__5D9sz {
  margin-top: 8px !important;
  margin-bottom: 50px !important; }

.MedicalDataForm_formControl__JYo83 {
  margin-top: 8px;
  min-width: 120px; }
  .MedicalDataForm_formControl__JYo83 label {
    color: #50a7b4 !important; }
  .MedicalDataForm_formControl__JYo83 div fieldset {
    border-color: #50a7b4 !important; }
  .MedicalDataForm_formControl__JYo83 svg {
    color: #50a7b4 !important; }

.MedicalDataForm_stepper__1rrl- {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .MedicalDataForm_stepper__1rrl- {
      position: fixed; } }

.MedicalDataForm_flexColumn__2aH0T {
  display: flex;
  flex-direction: column; }
  .MedicalDataForm_flexColumn__2aH0T button {
    background-color: #4FA9B3;
    color: white; }
  .MedicalDataForm_flexColumn__2aH0T button:hover {
    background-color: #448086;
    color: white; }
  .MedicalDataForm_flexColumn__2aH0T button:disabled {
    background-color: white;
    color: #4FA9B3; }

.MedicalDataForm_noEqualPasswordsMessage__1whbX {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.MedicalDataForm_noEqualPasswordsMessageBox__1OVvt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.MedicalDataForm_paymentModalTitleBox__3wfFh {
  display: flex;
  align-items: center; }

.MedicalDataForm_sendingDataAnimation__8WhpV {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.MedicalDataForm_textField__1XGEn {
  margin-top: 20px !important; }
  .MedicalDataForm_textField__1XGEn label {
    color: #50a7b4 !important; }
  .MedicalDataForm_textField__1XGEn div fieldset {
    border-color: #50a7b4 !important; }

.MedicalDataForm_chipInputLabel__3MCzv {
  margin-bottom: 5px;
  color: #50a7b4 !important; }

.MedicalDataForm_chip__Pn3yh {
  margin: 5px; }

.MedicalDataForm_contentText__2IzVE {
  border-bottom: 10px; }

.MedicalDataForm_tutoWrapper__VLvGd {
  display: flex;
  flex-direction: column;
  text-align: center; }

.MedicalDataForm_tutoImgWrapper__1wGWj {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .MedicalDataForm_tutoParentWrapper__VEdAS:after, .MedicalDataForm_tutoParentWrapper__VEdAS:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .MedicalDataForm_tutoParentWrapper__VEdAS:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .MedicalDataForm_tutoParentWrapper__VEdAS:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .MedicalDataForm_UxTutoMask__232Lg {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.ChipInput_chip__12NAK {
  margin: 2px 5px; }

.ChipInput_textField__3HRJm {
  margin-left: 10px; }
  .ChipInput_textField__3HRJm label {
    color: #50a7b4 !important; }
  .ChipInput_textField__3HRJm div fieldset {
    border-color: #50a7b4 !important; }
  .ChipInput_textField__3HRJm .ChipInput_contentText__3f-Ci {
    border-bottom: 10px; }

.ChipInput_gridContainer__3aLYI {
  margin: 0px 2.5px !important;
  width: unset !important; }

.ChipInput_parentGrid__1AJ3N {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
  border: 1px solid #50a7b4 !important;
  border-radius: 5px; }

.ChipInput_typography__yOM5j {
  margin-left: 5px !important;
  margin-top: 2px !important; }

.ChipInput_label__9a0hd {
  color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.DocumentsDataForm_sendingDataAnimation__2uV9E {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.DocumentsDataForm_container__2_js1 {
  margin-bottom: 50px; }

.DocumentsDataForm_formControl__2HLLt {
  display: flex !important; }
  .DocumentsDataForm_formControl__2HLLt label {
    color: #50a7b4 !important; }
  .DocumentsDataForm_formControl__2HLLt div fieldset {
    border-color: #50a7b4 !important; }
    .DocumentsDataForm_formControl__2HLLt div fieldset legend {
      max-width: 90px !important; }
  .DocumentsDataForm_formControl__2HLLt svg {
    color: #50a7b4 !important; }

.DocumentsDataForm_formControlType__2P8oR {
  display: flex !important; }
  @media (max-width: 768px) {
    .DocumentsDataForm_formControlType__2P8oR {
      margin-bottom: 20px !important; } }
  .DocumentsDataForm_formControlType__2P8oR label {
    color: #50a7b4 !important; }
  .DocumentsDataForm_formControlType__2P8oR div fieldset {
    border-color: #50a7b4 !important; }

.DocumentsDataForm_stepper__2sZPs {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .DocumentsDataForm_stepper__2sZPs {
      position: fixed; } }

.DocumentsDataForm_flexColumn__1c4IG {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .DocumentsDataForm_flexColumn__1c4IG button {
    background-color: #50a7b4;
    color: white; }
  .DocumentsDataForm_flexColumn__1c4IG button:hover {
    background-color: #448086;
    color: white; }
  .DocumentsDataForm_flexColumn__1c4IG button:disabled {
    background-color: white;
    color: #50a7b4; }

.DocumentsDataForm_noEqualPasswordsMessage__1R9bQ {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.DocumentsDataForm_noEqualPasswordsMessageBox__3pOcY {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.DocumentsDataForm_paymentModalTitleBox__2Rt37 {
  display: flex;
  align-items: center; }

.DocumentsDataForm_sendingDataAnimation__2uV9E {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.DocumentsDataForm_textField__i32f5 label {
  color: #50a7b4 !important; }

.DocumentsDataForm_textField__i32f5 div fieldset {
  border-color: #50a7b4 !important; }

.DocumentsDataForm_contentText__1JViO {
  border-bottom: 10px; }

.DocumentsDataForm_doubleColumnContainer__60tE_ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.DocumentsDataForm_parentContainer__3vdGi {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.DocumentsDataForm_docsListContainer__1TIY7 {
  background-color: #f7f7f7;
  border-radius: 25px; }
  .DocumentsDataForm_docsListContainer__1TIY7 .DocumentsDataForm_document__1Rr0E .DocumentsDataForm_documentHeader__3TYoV {
    display: flex;
    align-items: center; }
  .DocumentsDataForm_docsListContainer__1TIY7 .DocumentsDataForm_contactsListContainerTitle__2KUGd {
    margin: 10px 15px 0;
    color: #979797; }

.DocumentsDataForm_documentsContainer__34V3L {
  display: flex;
  justify-content: space-around;
  align-items: baseline !important;
  margin-top: 10px !important; }

.DocumentsDataForm_documentFormControlContainer__2zaBR {
  align-items: flex-start;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .DocumentsDataForm_documentFormControlContainer__2zaBR {
      display: flex; } }
  @media (max-width: 768px) {
    .DocumentsDataForm_documentFormControlContainer__2zaBR {
      flex-direction: column !important; } }

.DocumentsDataForm_uploadFileContainer__1hHOm {
  display: flex;
  flex-wrap: wrap;
  min-height: 65px;
  align-items: center; }
  @media (max-width: 768px) {
    .DocumentsDataForm_uploadFileContainer__1hHOm {
      flex-direction: column;
      margin-top: 25px !important; } }

.DocumentsDataForm_uploadFilebutton__3piCT {
  background-color: #50a7b4 !important;
  color: white !important;
  margin-right: 5px !important;
  margin-left: 12px !important; }

.DocumentsDataForm_uploadFilebutton__3piCT:hover {
  background-color: #448086 !important;
  color: white !important; }

.DocumentsDataForm_fileNameContainer__1H3d1 {
  display: flex;
  align-items: center;
  border-left: 3px solid #448086;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .DocumentsDataForm_fileNameContainer__1H3d1 {
      flex-direction: column;
      margin-top: 15px;
      margin-left: 0; } }

.DocumentsDataForm_addButton__17wfr {
  margin-top: 15px;
  background-color: #50a7b4 !important; }

.DocumentsDataForm_addButton__17wfr:hover {
  background-color: #448086 !important; }

.DocumentsDataForm_addButton__17wfr:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.DocumentsDataForm_addButtonIcon__3rmsX {
  margin-right: 5px; }

.DocumentsDataForm_newDocumentFormContainer__-pXxz {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 1em !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.DocumentsDataForm_newDocumentActionButtons___Bohs {
  display: flex;
  justify-content: flex-end; }

.DocumentsDataForm_tutoWrapper__3uJU9 {
  display: flex;
  flex-direction: column;
  text-align: center; }

.DocumentsDataForm_tutoImgWrapper__1q9AN {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .DocumentsDataForm_tutoParentWrapper__1gKYq:after, .DocumentsDataForm_tutoParentWrapper__1gKYq:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .DocumentsDataForm_tutoParentWrapper__1gKYq:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .DocumentsDataForm_tutoParentWrapper__1gKYq:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .DocumentsDataForm_UxTutoMask__20w4s {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.ContactsDataForm_container__11Bit {
  margin-bottom: 50px; }

.ContactsDataForm_formControl__2bWMP {
  display: flex !important; }
  .ContactsDataForm_formControl__2bWMP label {
    color: #50a7b4 !important; }
  .ContactsDataForm_formControl__2bWMP div fieldset {
    border-color: #50a7b4 !important; }
    .ContactsDataForm_formControl__2bWMP div fieldset legend {
      max-width: 90px !important; }
  .ContactsDataForm_formControl__2bWMP svg {
    color: #50a7b4 !important; }

.ContactsDataForm_stepper__2gEsb {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .ContactsDataForm_stepper__2gEsb {
      position: fixed; } }

.ContactsDataForm_autocompleteLocationInputs__uaiTI {
  display: flex;
  flex-direction: column; }

.ContactsDataForm_flexColumn__bdYwi {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .ContactsDataForm_flexColumn__bdYwi button {
    background-color: #4FA9B3;
    color: white; }
  .ContactsDataForm_flexColumn__bdYwi button:hover {
    background-color: #448086;
    color: white; }
  .ContactsDataForm_flexColumn__bdYwi button:disabled {
    background-color: white;
    color: #4FA9B3; }

.ContactsDataForm_noEqualPasswordsMessage__c4TBp {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.ContactsDataForm_noEqualPasswordsMessageBox__2cAh1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.ContactsDataForm_paymentModalTitleBox__qh4nL {
  display: flex;
  align-items: center; }

.ContactsDataForm_sendingDataAnimation__1WvqP {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.ContactsDataForm_contentText__1HzfN {
  border-bottom: 10px; }

.ContactsDataForm_formContainer__15YZo {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.ContactsDataForm_doubleColumnContainer__3TAgw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.ContactsDataForm_parentContainer__3b9Al {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.ContactsDataForm_contactsListContainer__1oR8y {
  background-color: #f7f7f7;
  border-radius: 25px; }
  .ContactsDataForm_contactsListContainer__1oR8y .ContactsDataForm_contactsListContainerTitle__2I0i2 {
    margin: 10px 15px 0;
    color: #979797; }
  .ContactsDataForm_contactsListContainer__1oR8y .ContactsDataForm_document__2pIRL .ContactsDataForm_documentHeader__2o0ne {
    display: flex;
    align-items: center; }

.ContactsDataForm_contactsContainer__1cuof {
  display: flex;
  justify-content: center;
  margin-top: 10px !important; }

.ContactsDataForm_addButton__3xfkh {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  background-color: #50a7b4 !important; }

.ContactsDataForm_addButton__3xfkh:hover {
  background-color: #448086 !important; }

.ContactsDataForm_addButton__3xfkh:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.ContactsDataForm_addButtonIcon__1EQaY {
  margin-right: 5px; }

.ContactsDataForm_relationshipText__3EuFN {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.ContactsDataForm_inputAdornment__2j1HI {
  color: #716b77; }

.ContactsDataForm_newContactFormContainer__3zUGt {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 1em !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.ContactsDataForm_newContactActionButtons__2gYBC {
  display: flex;
  justify-content: flex-end; }

.ContactsDataForm_redoIcon__1w0LK {
  transform: rotate(45deg); }

.ContactsDataForm_addContactButton__2IUEX {
  background-color: #4FA9B3;
  color: #fff; }

.ContactsDataForm_tutoWrapper__1vbR7 {
  display: flex;
  flex-direction: column;
  text-align: center; }

.ContactsDataForm_tutoImgWrapper__2dmok {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .ContactsDataForm_tutoParentWrapper__3yRGA:after, .ContactsDataForm_tutoParentWrapper__3yRGA:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .ContactsDataForm_tutoParentWrapper__3yRGA:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .ContactsDataForm_tutoParentWrapper__3yRGA:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .ContactsDataForm_UxTutoMask__2tzTV {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.PhotosDialog_formControl__3TEjq {
  margin-top: 8px;
  min-width: 120px; }

.PhotosDialog_flexColumn__7PeLp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .PhotosDialog_flexColumn__7PeLp button {
    background-color: #50a7b4;
    color: white; }
  .PhotosDialog_flexColumn__7PeLp button:hover {
    background-color: #448086;
    color: white; }
  .PhotosDialog_flexColumn__7PeLp .PhotosDialog_photo__25lup {
    margin-top: 15px;
    border-radius: 15px;
    background: url(/static/media/loading.e4987724.gif) 50% no-repeat;
    height: auto;
    width: auto;
    min-width: 75px;
    min-height: 75px;
    max-width: 500px;
    max-height: 500px; }
    @media (max-width: 480px) {
      .PhotosDialog_flexColumn__7PeLp .PhotosDialog_photo__25lup {
        max-height: 400px;
        max-width: 300px; } }
  .PhotosDialog_flexColumn__7PeLp .PhotosDialog_stepNextButton__1lZae {
    position: absolute !important;
    top: 50% !important;
    right: 5px !important; }
  .PhotosDialog_flexColumn__7PeLp .PhotosDialog_stepBeforeButton__F6QsF {
    position: absolute !important;
    top: 50% !important;
    left: 5px !important; }

.PhotosDialog_closeModalIcon__3OjU5 {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.PhotosDialog_textField__1vmiX label {
  color: #50a7b4 !important; }

.PhotosDialog_textField__1vmiX div fieldset {
  border-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.EmergencyStepsModal_container__8jSRp {
  margin-bottom: 50px; }

.EmergencyStepsModal_formControl__28KIi {
  display: flex !important; }
  .EmergencyStepsModal_formControl__28KIi label {
    color: #50a7b4 !important; }
  .EmergencyStepsModal_formControl__28KIi div fieldset {
    border-color: #50a7b4 !important; }
    .EmergencyStepsModal_formControl__28KIi div fieldset legend {
      max-width: 90px !important; }
  .EmergencyStepsModal_formControl__28KIi svg {
    color: #50a7b4 !important; }

.EmergencyStepsModal_formControlType__1-bX1 {
  display: flex !important; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_formControlType__1-bX1 {
      margin-bottom: 20px !important; } }
  .EmergencyStepsModal_formControlType__1-bX1 label {
    color: #50a7b4 !important; }
  .EmergencyStepsModal_formControlType__1-bX1 div fieldset {
    border-color: #50a7b4 !important; }

.EmergencyStepsModal_stepper__3cllI {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_stepper__3cllI {
      position: fixed; } }

.EmergencyStepsModal_flexColumn__2-n3T {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .EmergencyStepsModal_flexColumn__2-n3T button {
    background-color: #50a7b4;
    color: white; }
  .EmergencyStepsModal_flexColumn__2-n3T button:hover {
    background-color: #448086;
    color: white; }
  .EmergencyStepsModal_flexColumn__2-n3T button:disabled {
    background-color: white;
    color: #50a7b4; }

.EmergencyStepsModal_noEqualPasswordsMessage__Dva8e {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.EmergencyStepsModal_noEqualPasswordsMessageBox__17vj3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.EmergencyStepsModal_paymentModalTitleBox__1YTZJ {
  display: flex;
  align-items: center; }

.EmergencyStepsModal_sendingDataAnimation__1N482 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #448086 !important; }

.EmergencyStepsModal_textField__3q983 label {
  color: #50a7b4 !important; }

.EmergencyStepsModal_textField__3q983 div fieldset {
  border-color: #50a7b4 !important; }

.EmergencyStepsModal_contentText__2r7O4 {
  border-bottom: 10px; }

.EmergencyStepsModal_doubleColumnContainer__1EJIV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.EmergencyStepsModal_parentContainer__3Nmjf {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.EmergencyStepsModal_stepsListContainer__otE5P {
  background-color: #f7f7f7;
  border-radius: 25px; }
  .EmergencyStepsModal_stepsListContainer__otE5P .EmergencyStepsModal_document__2ZBE7 .EmergencyStepsModal_documentHeader__PaPMy {
    display: flex;
    align-items: center; }
  .EmergencyStepsModal_stepsListContainer__otE5P .EmergencyStepsModal_contactsListContainerTitle__wW1vL {
    margin: 10px 15px 0;
    color: #979797; }

.EmergencyStepsModal_stepsContainer__16Bjj {
  display: flex;
  justify-content: space-around;
  align-items: baseline !important;
  margin-top: 10px !important; }

.EmergencyStepsModal_stepsGrid__37Y-w {
  margin-top: 1em !important; }

.EmergencyStepsModal_stepsFormControlContainer__INw9s {
  align-items: flex-start;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .EmergencyStepsModal_stepsFormControlContainer__INw9s {
      display: flex; } }
  @media (max-width: 768px) {
    .EmergencyStepsModal_stepsFormControlContainer__INw9s {
      flex-direction: column !important; } }

.EmergencyStepsModal_uploadFileContainer__31723 {
  display: flex;
  flex-wrap: wrap;
  min-height: 65px;
  align-items: center; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_uploadFileContainer__31723 {
      flex-direction: column;
      margin-top: 25px !important; } }

.EmergencyStepsModal_uploadFilebutton__2bSg1 {
  background-color: #50a7b4 !important;
  color: white !important;
  margin-right: 5px !important;
  margin-left: 12px !important; }

.EmergencyStepsModal_uploadFilebutton__2bSg1:hover {
  background-color: #448086 !important;
  color: white !important; }

.EmergencyStepsModal_fileNameContainer__14XiT {
  display: flex;
  align-items: center;
  border-left: 3px solid #448086;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_fileNameContainer__14XiT {
      flex-direction: column;
      margin-top: 15px;
      margin-left: 0; } }

.EmergencyStepsModal_addButton__2KDTk, .EmergencyStepsModal_saveButton__1ZFQj {
  margin-top: 15px;
  background-color: #50a7b4 !important; }

.EmergencyStepsModal_addButton__2KDTk:hover, .EmergencyStepsModal_saveButton__1ZFQj:hover {
  background-color: #448086 !important; }

.EmergencyStepsModal_addButton__2KDTk:disabled, .EmergencyStepsModal_saveButton__1ZFQj:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.EmergencyStepsModal_addButtonIcon__2a_gl {
  margin-right: 5px; }

.EmergencyStepsModal_saveButton__1ZFQj {
  margin-left: 1em !important; }

.EmergencyStepsModal_newStepFormContainer__2jwU9 {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 1em !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.EmergencyStepsModal_newStepActionButtons__3rCuL {
  display: flex;
  justify-content: flex-end; }

.EmergencyStepsModal_closeModalIcon__2DGLw {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.EmergencyStepsModal_listItemAction__1wZoK {
  color: red; }

.EmergencyStepsModal_listItem__yoqeT {
  border-left: 3px solid #448086;
  margin: 1em; }

.EmergencyStepsModal_listItemDragging__MW5d0 {
  background-color: #50a7b4;
  border-radius: 25px; }

.EmergencyStepsModal_dialogSubtext__TB0Av {
  color: #a5a5a5 !important; }

.EmergencyStepsModal_tutoWrapper__xBkrQ {
  display: flex;
  flex-direction: column;
  text-align: center; }

.EmergencyStepsModal_tutoImgWrapper__Frq1M {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .EmergencyStepsModal_tutoParentWrapper__L0y6O:after, .EmergencyStepsModal_tutoParentWrapper__L0y6O:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .EmergencyStepsModal_tutoParentWrapper__L0y6O:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .EmergencyStepsModal_tutoParentWrapper__L0y6O:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .EmergencyStepsModal_UxTutoMask__2UoS7 {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
h2 {
  color: white;
  text-align: center;
  font-size: 30px; }
  @media (max-width: 768px) {
    h2 {
      margin-top: 0;
      font-size: 20px; } }

.BraceletList_rateUsContainer__2-CJA {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #50a7b4;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -2px; }
  @media (min-width: 1024px) {
    .BraceletList_rateUsContainer__2-CJA {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  .BraceletList_rateUsContainer__2-CJA .BraceletList_rateUsCopy__1Kp6d {
    margin: 1px; }
  .BraceletList_rateUsContainer__2-CJA .BraceletList_rateIconItem__3xIgO {
    display: flex;
    justify-content: center; }
    .BraceletList_rateUsContainer__2-CJA .BraceletList_rateIconItem__3xIgO .BraceletList_rateIconButton__1H-cn {
      background-color: rgba(255, 255, 255, 0.89); }
    .BraceletList_rateUsContainer__2-CJA .BraceletList_rateIconItem__3xIgO .BraceletList_rateIcon__xkBXt {
      width: 3.5rem; }

.BraceletList_list__19dk2 {
  background-color: #50a7b4;
  padding-bottom: 75px; }
  .BraceletList_list__19dk2 .BraceletList_contentSingle__IGMs7 {
    min-height: 95vh;
    padding: 8em 18px 18px; }
  .BraceletList_list__19dk2 .BraceletList_content__1xfP8 {
    padding-top: 85px;
    min-height: 95vh; }
    @media (max-width: 374px) {
      .BraceletList_list__19dk2 .BraceletList_content__1xfP8 {
        padding-top: 135px; } }
  .BraceletList_list__19dk2 .BraceletList_noContent__2zIUz {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 58vh; }
  .BraceletList_list__19dk2 .BraceletList_chargingContent__nBwBT {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    min-height: 95vh; }
  .BraceletList_list__19dk2 .BraceletList_chargingCircle__1lKiZ {
    color: #fff8e3; }
  .BraceletList_list__19dk2 ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    margin-top: 35px;
    padding-bottom: 3em; }
    .BraceletList_list__19dk2 ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      margin-top: 20px; }
      .BraceletList_list__19dk2 ul li p, .BraceletList_list__19dk2 ul li h3 {
        margin-right: 10px; }
  .BraceletList_list__19dk2 .BraceletList_card__oMkzJ {
    width: 100%; }
  .BraceletList_list__19dk2 .BraceletList_errorMessage__2gLIq {
    color: #fff;
    text-align: center; }
  .BraceletList_list__19dk2 .BraceletList_buttonNew__Uc0Hw {
    position: fixed;
    margin: 10px;
    right: 10px;
    bottom: 10px;
    background-color: #fff8e3;
    color: #448086;
    box-shadow: 5px 6px 8px grey;
    font-weight: bold; }
  .BraceletList_list__19dk2 .BraceletList_buttonNew__Uc0Hw:hover {
    background-color: #efe9d6; }

.BraceletList_shareButtonsBox__B9ysG {
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.BraceletList_shareButton__2rfLj:hover {
  cursor: pointer; }

.BraceletList_multipleSelectionButton__2KiZ3 {
  position: fixed !important;
  margin: 10px !important;
  right: 10px !important;
  bottom: 60px !important;
  background-color: #ffffff !important;
  color: #448086 !important;
  box-shadow: 0px 5px 19px grey; }

.BraceletList_multipleSelectionControls__d3793 {
  display: flex;
  position: fixed;
  bottom: 0;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  background-color: #fff;
  box-shadow: -1px 2px 42px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  z-index: 300;
  justify-content: space-evenly;
  align-items: center; }
  .BraceletList_multipleSelectionControls__d3793 .BraceletList_multipleSelectionInfo__2chKs {
    display: flex;
    align-items: center; }
    .BraceletList_multipleSelectionControls__d3793 .BraceletList_multipleSelectionInfo__2chKs .BraceletList_multipleSelectionInfoClose__2REe7 {
      margin-right: 0.5em; }

.BraceletList_closeModalIcon__1T6ZM {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BraceletList_sendingDataAnimation__1NFG4 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BraceletList_floatingActiveBraceletButton__337nL {
  position: fixed !important;
  bottom: 1em;
  right: 1em;
  z-index: 99 !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  box-shadow: 3px 7px 20px 0px black; }

.BraceletList_activeIcon__1QuvV {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.BraceletList_getNewPulsIdButton__2quep {
  background-color: #ff8f00 !important;
  color: #ffffff !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.BraceletCard_card__75eru {
  width: 80%; }
  @media (max-width: 768px) {
    .BraceletCard_card__75eru {
      width: 95%; } }
  .BraceletCard_card__75eru .BraceletCard_actions__29OYl {
    justify-content: flex-end; }
    .BraceletCard_card__75eru .BraceletCard_actions__29OYl .BraceletCard_covidButton__2sJIc {
      background-color: #4FA9B3;
      color: white;
      border-color: white;
      border-radius: 50px; }
  .BraceletCard_card__75eru .BraceletCard_assignContainer__2K03w {
    display: flex;
    align-items: center;
    padding: 0 20px 20px;
    justify-content: flex-end; }
    @media (max-width: 480px) {
      .BraceletCard_card__75eru .BraceletCard_assignContainer__2K03w {
        justify-content: center; } }
    .BraceletCard_card__75eru .BraceletCard_assignContainer__2K03w .BraceletCard_assignButton__11Tzj {
      text-transform: none;
      background-color: #50a7b4;
      border: none;
      border-radius: 6px;
      font-size: 20px;
      padding: 5px 10px;
      color: #fff;
      cursor: pointer; }
    .BraceletCard_card__75eru .BraceletCard_assignContainer__2K03w .BraceletCard_assignButton__11Tzj:hover {
      background-color: #448086 !important; }
  .BraceletCard_card__75eru .BraceletCard_cardDataContainer__3Em3X {
    display: flex;
    justify-content: space-between;
    padding: 10px; }
    @media (max-width: 768px) {
      .BraceletCard_card__75eru .BraceletCard_cardDataContainer__3Em3X {
        flex-direction: column; } }
    .BraceletCard_card__75eru .BraceletCard_cardDataContainer__3Em3X .BraceletCard_ListItemIcon__2j1yq div {
      display: flex;
      align-items: center; }
      .BraceletCard_card__75eru .BraceletCard_cardDataContainer__3Em3X .BraceletCard_ListItemIcon__2j1yq div svg {
        margin-right: 5px; }
    .BraceletCard_card__75eru .BraceletCard_cardDataContainer__3Em3X li {
      justify-content: space-between; }
      .BraceletCard_card__75eru .BraceletCard_cardDataContainer__3Em3X li h3 {
        margin: 0; }
    @media (max-width: 768px) {
      .BraceletCard_card__75eru .BraceletCard_cardDataContainer__3Em3X .BraceletCard_responsiveRow__1-UU0 {
        flex-direction: column;
        align-items: flex-start; } }
  .BraceletCard_card__75eru .BraceletCard_avatar__1oZxz {
    flex: 0 0 auto;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 768px) {
      .BraceletCard_card__75eru .BraceletCard_avatar__1oZxz {
        margin-right: 0; } }
  .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-top: 10px; }
    .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ div {
      margin: 10px 0 0; }
    .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ .BraceletCard_logoImg__2XJOu {
      width: 150px;
      margin-bottom: 10px; }
    .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ .BraceletCard_visibilityIcon__3f5m1 {
      color: #50a7b4;
      font-size: 35px;
      margin-top: 10px;
      visibility: hidden;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      font-size: 45px;
      z-index: 10;
      top: 35%;
      transform: translateY(-35%);
      /* Chrome, Safari, Opera */
      filter: drop-shadow(1px 1px 5px black); }
  @media (min-width: 768px) {
    .BraceletCard_card__75eru .BraceletCard_visibilityIcon__3f5m1 {
      visibility: visible;
      z-index: 10; }
    .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ:hover {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer; }
      .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ:hover .BraceletCard_qrImage__1ve8h {
        transition: all .15s ease;
        /* Chrome, Safari, Opera */
        filter: blur(3px); }
      .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ:hover div {
        margin: 10px 0 0; }
      .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ:hover .BraceletCard_logoImg__2XJOu {
        width: 150px;
        margin-bottom: 10px; }
      .BraceletCard_card__75eru .BraceletCard_avatarContainer__1xewZ:hover .BraceletCard_visibilityIcon__3f5m1 {
        visibility: visible;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        color: #fff9e4;
        left: 0;
        right: 0;
        z-index: 10;
        top: 35%;
        transform: translateY(-35%);
        /* Chrome, Safari, Opera */
        filter: drop-shadow(1px 1px 5px black); } }
  .BraceletCard_card__75eru .BraceletCard_avatarBraceletContainer__2d9Rk {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .BraceletCard_card__75eru .BraceletCard_avatarBraceletContainer__2d9Rk div {
      margin: 10px 0 0; }
    .BraceletCard_card__75eru .BraceletCard_avatarBraceletContainer__2d9Rk .BraceletCard_logoImg__2XJOu {
      width: 128px; }
  .BraceletCard_card__75eru .BraceletCard_cardHeader__ckBv8 {
    width: 88%;
    display: flex; }
    @media (max-width: 480px) {
      .BraceletCard_card__75eru .BraceletCard_cardHeader__ckBv8 {
        flex-direction: column; } }
  .BraceletCard_card__75eru .BraceletCard_cardHeaderTitle__2CwgI {
    text-align: start;
    font-size: 19px !important;
    font-family: Blogger-sans !important; }
    @media (max-width: 480px) {
      .BraceletCard_card__75eru .BraceletCard_cardHeaderTitle__2CwgI {
        text-align: center;
        margin-top: 5px; } }
  .BraceletCard_card__75eru .BraceletCard_subheaderCardText__1Scvg {
    font-size: 19px !important; }
    @media (max-width: 480px) {
      .BraceletCard_card__75eru .BraceletCard_subheaderCardText__1Scvg {
        text-align: center; } }

.BraceletCard_paddedList__18VtJ {
  padding-left: 15px; }

.BraceletCard_ListIcon__3HPbE {
  color: #000000; }

.BraceletCard_contactDataContainer__2-7ku {
  position: relative; }
  .BraceletCard_contactDataContainer__2-7ku .BraceletCard_contactDataEnable__2lRh2 {
    position: absolute;
    right: 0;
    bottom: 10px; }

.BraceletCard_documentTypeText__2VNAF {
  min-width: 55px; }

.BraceletCard_documentViewPhotos__3uE5d {
  font-size: 13px;
  color: #50a7b4;
  font-weight: bold;
  cursor: pointer; }
  @media (min-width: 768px) {
    .BraceletCard_documentViewPhotos__3uE5d {
      margin-left: 2em; } }

.BraceletCard_documentSecondaryBox__3k6du {
  display: flex; }
  @media (max-width: 480px) {
    .BraceletCard_documentSecondaryBox__3k6du {
      flex-direction: column; } }

.BraceletCard_gridStepsContainer__3pkKE {
  background-color: #f7f7f7 !important;
  border-radius: 25px !important;
  margin-bottom: 2em !important; }

.BraceletCard_gridContainer__3tg51 {
  background-color: #f7f7f7 !important;
  border-radius: 25px !important;
  display: flex;
  flex-direction: row; }

.BraceletCard_relationshipText__2AypT {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.BraceletCard_subheaderText__3rjMH {
  font-weight: bold;
  font-size: 16px; }

.BraceletCard_braceletStateActions__3H0zR {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 1em !important;
  flex-direction: column !important; }
  @media (min-width: 768px) {
    .BraceletCard_braceletStateActions__3H0zR {
      flex-direction: row !important; } }

@media (max-width: 768px) {
  .BraceletCard_deleteButtons__2-E9K {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; } }

.BraceletCard_customIcon__2OeM4 {
  width: 24px; }

.BraceletCard_addButton__Zx_25, .BraceletCard_saveButton__1qx8p {
  margin-top: 15px;
  background-color: #50a7b4 !important; }

.BraceletCard_addButton__Zx_25:hover, .BraceletCard_saveButton__1qx8p:hover {
  background-color: #448086 !important; }

.BraceletCard_addButton__Zx_25:disabled, .BraceletCard_saveButton__1qx8p:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.BraceletCard_addButtonIcon__3wtpU {
  margin-right: 5px; }

.BraceletCard_erasePatientDataButton__20gTi {
  margin-right: 2em !important; }
  @media (max-width: 768px) {
    .BraceletCard_erasePatientDataButton__20gTi {
      margin-right: 0 !important; } }

@media (max-width: 768px) {
  .BraceletCard_erasePatientDataButton__20gTi:nth-child(2) {
    margin-top: 1rem; } }

.BraceletCard_closeModalIcon__3GR5z {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BraceletCard_sendingDataAnimation__3SiT8 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BraceletCard_downloadQrButton__1voVp {
  margin-top: 0.75rem !important;
  background-color: #50a7b4 !important;
  color: #ffffff !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PasswordChangeModal_formControl__1KWZI {
  margin-top: 8px;
  min-width: 120px; }

.PasswordChangeModal_flexColumn__15IC_ {
  display: flex;
  flex-direction: column; }

.PasswordChangeModal_actionsContainer__34q21 button {
  background-color: #50a7b4;
  color: white; }

.PasswordChangeModal_actionsContainer__34q21 button:hover {
  background-color: #448086;
  color: white; }

.PasswordChangeModal_noEqualPasswordsMessage__2neSU {
  color: #ff483f !important;
  font-style: italic;
  margin-left: 10px !important; }

.PasswordChangeModal_noEqualPasswordsMessageBox__ECwJH {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center !important;
  color: #ff483f !important;
  font-style: italic; }

.PasswordChangeModal_paymentModalTitleBox__183PB {
  display: flex;
  align-items: center; }

.PasswordChangeModal_sendingDataAnimation__3nu23 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.PasswordChangeModal_textField__31KaO label {
  color: #50a7b4 !important; }

.PasswordChangeModal_textField__31KaO div {
  padding-right: 0 !important; }
  .PasswordChangeModal_textField__31KaO div fieldset {
    border-color: #50a7b4 !important; }

.PasswordChangeModal_textField__31KaO .PasswordChangeModal_contentText__25muK {
  border-bottom: 10px; }

@font-face {
    font-family: 'Roboto Medium';
    src: url(/static/media/Roboto-Medium.58aef543.ttf) format('ttf');
}
@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Bold.e07df86c.ttf) format('ttf');
}

html, body, #app, #app>div, li, span, p, input, a, h2, h3, h4, h5, h6, textarea, legend, option, div {
  /*height: 100%*/
    /*font-family: blogger-sans, serif !important;*/
    font-family: 'Roboto', sans-serif !important; ;

}


/*font-family*/
/*font-size*/
/*font-weight*/
.codeModal_mainContainer__9bmVE {
  margin-top: 0.5rem; }

.codeModal_formControl__oGSLp {
  margin-top: 8px;
  min-width: 120px; }

.codeModal_flexColumn__3bCLq {
  display: flex;
  flex-direction: column; }
  .codeModal_flexColumn__3bCLq button {
    background-color: #50a7b4;
    color: white; }
  .codeModal_flexColumn__3bCLq button:hover {
    background-color: #448086;
    color: white; }

.codeModal_checkbox__1U0qg {
  color: #50a7b4 !important; }

.codeModal_noEqualPasswordsMessage__2kUnW {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.codeModal_noEqualPasswordsMessageBox__1slqH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.codeModal_paymentModalTitleBox__31bkf {
  display: flex;
  align-items: center; }

.codeModal_privacyLink__2M57P {
  color: #716b77; }

.codeModal_privacyLink__2M57P:hover {
  color: #0077b5;
  text-decoration: underline; }

.codeModal_sendingDataAnimation__35kB- {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.codeModal_closeModalIcon__3xkXM {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.codeModal_forgottenPasswordText__3xprK {
  margin-top: 10px !important;
  font-style: italic; }

.codeModal_textField__2nNM5 label {
  color: #50a7b4 !important; }

.codeModal_textField__2nNM5 div fieldset {
  border-color: #50a7b4 !important; }

.codeModal_buttonSubmit__36QCq {
  padding: 3em !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes notFound_floatBracelet__BEqbI {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes notFound_floatVerticalBracelet__1NPpr {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes notFound_backgroundGradient__22rN0 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes notFound_backgroundGradient2__28WCY {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.notFound_notFound__31Kla {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  padding: 4% 20% 4% 20%;
  text-align: justify; }
  @media (max-width: 768px) {
    .notFound_notFound__31Kla {
      padding: 4% 20px 4% 20px;
      margin-top: 50px; } }
  .notFound_notFound__31Kla .notFound_numContainer__KK9Gm {
    display: flex;
    align-items: center;
    justify-content: center; }
    .notFound_notFound__31Kla .notFound_numContainer__KK9Gm h1 {
      color: #50a7b4;
      font-size: 180px; }
      @media (max-width: 768px) {
        .notFound_notFound__31Kla .notFound_numContainer__KK9Gm h1 {
          font-size: 160px; } }
    .notFound_notFound__31Kla .notFound_numContainer__KK9Gm img {
      margin-bottom: 35px;
      margin-left: 10px;
      margin-right: -5px;
      height: 100px;
      animation: notFound_floatVerticalBracelet__1NPpr 6s ease-in-out infinite; }
      @media (max-width: 768px) {
        .notFound_notFound__31Kla .notFound_numContainer__KK9Gm img {
          height: 90px; } }
  .notFound_notFound__31Kla .notFound_comeBackContainer__1B9DV {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .notFound_notFound__31Kla .notFound_comeBackContainer__1B9DV h2 {
      color: #716b77; }

.notFound_navBar__3AFeH {
  background-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes tutorialCompra_floatBracelet__1gKDD {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes tutorialCompra_floatVerticalBracelet__3ad1O {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes tutorialCompra_backgroundGradient__2Dzln {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes tutorialCompra_backgroundGradient2__1McgT {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.tutorialCompra_container__3Vev4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff8e3;
  min-height: 100vh; }

.tutorialCompra_videoBox__228UJ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .tutorialCompra_videoBox__228UJ {
      margin-top: 100px; } }

.tutorialCompra_embedBox__4u6CL {
  margin: 1em auto;
  width: 65%;
  padding: 10px; }
  @media (min-width: 1024px) {
    .tutorialCompra_embedBox__4u6CL {
      padding-top: 7em; } }

.tutorialCompra_navBar__3N_RD {
  background-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes allTutorials_floatBracelet__120hm {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes allTutorials_floatVerticalBracelet__Mwqpc {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes allTutorials_backgroundGradient__2TqwV {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes allTutorials_backgroundGradient2__jF95O {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.allTutorials_container__22zn1 {
  display: flex;
  padding-top: 25%;
  justify-content: center;
  align-items: stretch;
  background-color: #fff8e3; }
  @media (min-width: 480px) {
    .allTutorials_container__22zn1 {
      padding-top: 12%; } }

.allTutorials_gridItem__sy3kH {
  padding: 1rem; }
  @media (max-width: 768px) {
    .allTutorials_gridItem__sy3kH {
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.allTutorials_videoTitle__1UhR6 {
  text-align: center;
  padding: 10px !important; }

.allTutorials_videoBox__3TZGN {
  margin: 15px;
  display: flex;
  background-color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.allTutorials_embedBox__1qJQi {
  margin: 1em auto;
  margin-top: 2rem !important;
  width: 65%;
  padding: 10px; }
  @media (max-width: 768px) {
    .allTutorials_embedBox__1qJQi {
      width: 83%; } }
  .allTutorials_embedBox__1qJQi div:first-child {
    border-radius: 12px !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important; }

.allTutorials_videoBorder__FBNt_ iframe {
  border-radius: 25px !important; }

.allTutorials_navBar__3iVuX {
  background-color: #50a7b4 !important; }

.allTutorials_buttonContainer__2k9wJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem; }
  .allTutorials_buttonContainer__2k9wJ .allTutorials_button__1K_1W {
    margin-top: 2rem;
    padding: 10px;
    background: transparent;
    color: #4FA9B3;
    border: 2px #50a7b4 solid;
    border-radius: 5px;
    font-size: 30px; }

.allTutorials_ctaContainer__14fau {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem; }
  @media (max-width: 768px) {
    .allTutorials_ctaContainer__14fau {
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.allTutorials_ctaTitle__1mFwI {
  text-align: center !important;
  margin-bottom: auto !important;
  padding: 10px; }

.allTutorials_ctaButton__1rfrV {
  margin-top: 2rem !important;
  background: #00BCD4 !important;
  color: #ffff !important;
  font-family: sans-serif !important;
  font-weight: bold !important;
  font-size: 17px !important;
  margin-bottom: 1rem !important;
  border-radius: 43rem !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important; }
  @media (min-width: 768px) {
    .allTutorials_ctaButton__1rfrV {
      width: 60% !important;
      padding: 2rem 2rem !important;
      margin-bottom: auto !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes tutorial_floatBracelet__1ppnI {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes tutorial_floatVerticalBracelet__2nhEI {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes tutorial_backgroundGradient__3ZcoS {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes tutorial_backgroundGradient2__2OoVn {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.tutorial_container__klQEo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff8e3;
  min-height: 100vh; }

.tutorial_videoBox__3OQTQ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .tutorial_videoBox__3OQTQ {
      margin-top: 100px; } }

.tutorial_embedBox__EM2Wo {
  margin: 1em auto;
  width: 65%;
  padding: 10px; }
  @media (min-width: 1024px) {
    .tutorial_embedBox__EM2Wo {
      padding-top: 7em; } }

.tutorial_navBar__3ZXlQ {
  background-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.ForgottenPasswordModal_dialogTitle__RYmc- {
  display: flex;
  align-items: baseline; }

.ForgottenPasswordModal_formControl__1DYlF {
  margin-top: 8px;
  min-width: 120px; }

.ForgottenPasswordModal_flexColumn__eW5Ld {
  display: flex;
  flex-direction: column; }
  .ForgottenPasswordModal_flexColumn__eW5Ld button {
    background-color: #50a7b4;
    color: white; }
  .ForgottenPasswordModal_flexColumn__eW5Ld button:hover {
    background-color: #448086;
    color: white; }

.ForgottenPasswordModal_checkbox__snhGz {
  color: #50a7b4 !important; }

.ForgottenPasswordModal_noEqualPasswordsMessage__3qCs_ {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.ForgottenPasswordModal_noEqualPasswordsMessageBox__10R6m {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.ForgottenPasswordModal_paymentModalTitleBox__1Z7lS {
  display: flex;
  align-items: center; }

.ForgottenPasswordModal_privacyLink__Vxfih {
  color: #716b77; }

.ForgottenPasswordModal_privacyLink__Vxfih:hover {
  color: #0077b5;
  text-decoration: underline; }

.ForgottenPasswordModal_sendingDataAnimation__33V8B {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.ForgottenPasswordModal_closeModalIcon__Ywqfp {
  margin-right: 1em; }

.ForgottenPasswordModal_forgottenPasswordText__aP7Ew {
  margin-top: 10px !important;
  font-style: italic; }

.ForgottenPasswordModal_textField__2BXjp label {
  color: #50a7b4 !important; }

.ForgottenPasswordModal_textField__2BXjp div fieldset {
  border-color: #50a7b4 !important; }

.ForgottenPasswordModal_buttonSubmit__1lw6j {
  padding: 3em !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes PollLanding_floatBracelet__Spn0z {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes PollLanding_floatVerticalBracelet__2IPg9 {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes PollLanding_backgroundGradient__2jwKZ {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes PollLanding_backgroundGradient2__7Gls6 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.PollLanding_gridContainer__2E0So {
  padding-top: 7em; }

.PollLanding_gridContainer__2E0So, .PollLanding_returnButtonContainer__2UHW6 {
  display: flex;
  justify-content: center; }

.PollLanding_thanksMessage__2Tmk7 {
  text-align: center; }

.PollLanding_returnButton__26qo- {
  background-color: #50a7b4 !important;
  color: #fff8e3 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes FAQ_floatBracelet__2vYds {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes FAQ_floatVerticalBracelet__2A3NQ {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes FAQ_backgroundGradient__1gVb3 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes FAQ_backgroundGradient2__m5wum {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.FAQ_parentContainer__2Mp0c {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 3em;
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%); }

.FAQ_faqCard__LMnmu {
  border-radius: 15px !important; }

.FAQ_headImageContainer__3vZP6 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem; }

@media (max-width: 768px) {
  .FAQ_headImage__34-cW {
    display: none; } }

.FAQ_headImageWrapper__FaPK1 {
  border-radius: 4px;
  padding: 1.8em;
  width: 100%;
  margin-top: -1em;
  text-align: center; }

.FAQ_panelDetails__2uINC {
  background-color: #eaf3ff;
  border-radius: 15px;
  margin: 0.7rem; }

.FAQ_panelDetailsTypography__3Wuia {
  font-size: 15px !important; }

.FAQ_backButton__26zkT {
  position: absolute;
  left: 25px;
  top: 8rem; }
  .FAQ_backButton__26zkT .FAQ_backIcon__8ihaW {
    margin-right: 0.3em; }

.FAQ_buttonContainer__1c_zy {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .FAQ_buttonContainer__1c_zy .FAQ_button__3YHNW {
    margin-top: 2rem;
    padding: 10px;
    background: transparent;
    color: #50a7b4;
    border: 2px #50a7b4 solid;
    border-radius: 5px;
    font-size: 30px; }
  .FAQ_buttonContainer__1c_zy .FAQ_primaryCTAButton__33Idv {
    margin-top: 2rem;
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .FAQ_buttonContainer__1c_zy .FAQ_primaryCTAButton__33Idv {
        font-size: 20px; } }
  .FAQ_buttonContainer__1c_zy .FAQ_primaryCTAButton__33Idv:hover {
    background-color: #ea605d; }

.FAQ_tapIcon__2Jxq9 {
  margin-left: 0.5rem;
  font-size: 2rem;
  transform: rotate(-25deg); }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes OurHistory_floatBracelet__20eyV {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes OurHistory_floatVerticalBracelet__3ykEI {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes OurHistory_backgroundGradient__1SQRC {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes OurHistory_backgroundGradient2__1sm0r {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.OurHistory_Landing__1Oav8 {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #ffffff; }
  .OurHistory_Landing__1Oav8 h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .OurHistory_Landing__1Oav8 h2 {
    color: white;
    text-align: center;
    font-size: 30px; }
    @media (max-width: 768px) {
      .OurHistory_Landing__1Oav8 h2 {
        margin-top: 0;
        font-size: 20px; } }
  .OurHistory_Landing__1Oav8 h3 {
    font-family: Blogger-sans; }
  .OurHistory_Landing__1Oav8 li {
    font-family: Blogger-sans; }
  .OurHistory_Landing__1Oav8 a {
    font-family: Blogger-sans; }
  .OurHistory_Landing__1Oav8 p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq {
    min-height: 100vh;
    padding: 2rem;
    padding-top: 12rem !important;
    padding-bottom: rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #06beb6, #74a7b2);
    margin-bottom: 5rem; }
    @media (max-width: 480px) {
      .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq {
        padding: 0;
        padding-top: 3rem;
        flex-direction: column;
        margin-bottom: 3rem; } }
    .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq .OurHistory_historyContainerContent__3XFLn {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq .OurHistory_historyContainerContent__3XFLn h1 {
        color: #fff; }
      .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq .OurHistory_historyContainerContent__3XFLn p {
        color: #fff; }
      @media (max-width: 768px) {
        .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq .OurHistory_historyContainerContent__3XFLn {
          padding-right: 2rem;
          padding-left: 2rem; } }
    .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq .OurHistory_historyImageContainer__gRu10 {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end; }
      .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq .OurHistory_historyImageContainer__gRu10 img {
        height: auto;
        width: 100%; }
        @media (min-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_historyContainer__jq5Kq .OurHistory_historyImageContainer__gRu10 img {
            width: 75%;
            border-radius: 25px; } }
  .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 {
        padding-top: 40px; } }
    .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentContainerContent__1idMn {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentContainerContent__1idMn {
          flex-direction: column-reverse; } }
    .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentTextContainer__cJ8Ja {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentTextContainer__cJ8Ja {
          max-width: 100%; } }
      .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentTextContainer__cJ8Ja h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentTextContainer__cJ8Ja p {
          max-width: 70%; } }
    .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentImageContainer__11PY4 {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentImageContainer__11PY4 {
          margin-left: 15px; } }
      .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentImageContainer__11PY4 img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_enviromentContainer__2kjS3 .OurHistory_enviromentImageContainer__11PY4 img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 0.25rem;
    flex-direction: row; }
    @media (max-width: 480px) {
      .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 {
        flex-direction: column; } }
    .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK {
      display: flex;
      justify-content: center;
      position: relative; }
      .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_sampleImg__3hE2A {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_sampleImg__3hE2A {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_sampleImg__3hE2A {
            height: 500px;
            padding-right: 4em; } }
      .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_mockStepsView__3Kivj {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_mockStepsView__3Kivj {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_mockStepsView__3Kivj {
            height: 450px;
            margin-right: -2.50em; } }
      .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_mockContainerImg__2ke9v {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_mockContainerImg__2ke9v {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentMock__1SEcK .OurHistory_mockContainerImg__2ke9v {
            left: 0; } }
    .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl {
          margin-right: 20px;
          margin-left: 20px; } }
      .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl h1 {
            padding-left: 66px; } }
      .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl .OurHistory_content__s7gma {
        display: flex;
        justify-content: space-around; }
      .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl .OurHistory_sampleList__3nXBB {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl .OurHistory_sampleList__3nXBB {
            font-size: 17px;
            padding-left: 0; } }
        .OurHistory_Landing__1Oav8 .OurHistory_sampleContainer__1YCk8 .OurHistory_contentList__6dtxl .OurHistory_sampleList__3nXBB li {
          display: flex;
          align-items: center; }
  .OurHistory_Landing__1Oav8 .OurHistory_rateUsContainer__1DLoC {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-bottom: -2px; }
    @media (min-width: 1024px) {
      .OurHistory_Landing__1Oav8 .OurHistory_rateUsContainer__1DLoC {
        padding-top: 3rem;
        padding-bottom: 4rem; } }
  .OurHistory_Landing__1Oav8 .OurHistory_rateUsCopy__qzMM5 {
    margin: 2rem; }
  .OurHistory_Landing__1Oav8 .OurHistory_rateIconItem__bIKtX {
    display: flex;
    justify-content: center; }
    .OurHistory_Landing__1Oav8 .OurHistory_rateIconItem__bIKtX .OurHistory_rateIconButton__2v2NU {
      background-color: rgba(255, 255, 255, 0.89); }
    .OurHistory_Landing__1Oav8 .OurHistory_rateIconItem__bIKtX .OurHistory_rateIcon__3fe8z {
      width: 3.5rem; }
  .OurHistory_Landing__1Oav8 .OurHistory_reviewsContainer__sU51n {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 4rem;
    margin-bottom: -2px; }
  .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo {
    margin: 1rem; }
    .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewHead__2h3wD {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff; }
      .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewLogo__3H10i {
        width: 150px;
        height: 150px;
        object-fit: cover;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewLogo__3H10i {
            width: 80px;
            height: 80px; } }
      .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewDataContainer__BmKZG {
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewDataContainer__BmKZG {
            max-width: 500px;
            margin-top: 20px;
            text-align: justify; } }
        .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewDataContainer__BmKZG .OurHistory_reviewTitle__1Y32b {
          margin-top: 1rem;
          color: #ffffff;
          text-align: center; }
        .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewDataContainer__BmKZG .OurHistory_starsContainer__1teWt {
          display: flex;
          align-items: center; }
        .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewDataContainer__BmKZG a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewDataContainer__BmKZG p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_reviewGridItem__39JAo .OurHistory_reviewContainerContent__35XXQ .OurHistory_reviewDataContainer__BmKZG h6 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%; }
    .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      padding-bottom: 3rem;
      min-height: 20rem;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column;
          min-height: 27rem; } }
      .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 .OurHistory_newsLogo__2RX7I {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 .OurHistory_newsLogo__2RX7I {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 .OurHistory_newsDataContainer__1bju- {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 .OurHistory_newsDataContainer__1bju- {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 .OurHistory_newsDataContainer__1bju- a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 .OurHistory_newsDataContainer__1bju- p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .OurHistory_Landing__1Oav8 .OurHistory_newsContainer__14gBN .OurHistory_newsContainerContent__YuBZ4 .OurHistory_newsDataContainer__1bju- h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z {
        align-items: center; } }
    .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_sevi__2UFtv {
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_codeModal__1dizB {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz {
          flex-direction: column; } }
      .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz .OurHistory_footerLawsContainer__kQsfI {
        display: flex;
        margin: 0.5rem; }
        .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz .OurHistory_footerLawsContainer__kQsfI p {
          margin: 0.25rem;
          font-size: 15px;
          color: #716b77; }
      .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz .OurHistory_seviLogo__374m6 {
        width: 150px; }
      .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz .OurHistory_footerLogosContainer__6Ee6h {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25rem;
        margin-top: 2rem; }
      .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz .OurHistory_footerContentServices__kx8XG {
        display: flex;
        flex-direction: column; }
        .OurHistory_Landing__1Oav8 .OurHistory_footerContainer__2om1z .OurHistory_footerContent__zm7Vz .OurHistory_footerContentServices__kx8XG div {
          max-width: 400px; }

.OurHistory_modalInfoHeader__4uaBj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.OurHistory_modalInfoContent__1V9Yd {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .OurHistory_modalInfoContent__1V9Yd {
      flex-direction: column; } }
  .OurHistory_modalInfoContent__1V9Yd li {
    font-size: 20px;
    margin-top: 10px; }
  .OurHistory_modalInfoContent__1V9Yd .OurHistory_imageHand__33RR9 {
    width: 15rem;
    height: 15rem; }
  .OurHistory_modalInfoContent__1V9Yd .OurHistory_imageKid__26Pkz {
    width: 11rem;
    height: 22rem; }

.OurHistory_seeMoreText__3M0Ij {
  text-decoration: underline; }

.OurHistory_cookiesLayer__2bH6n {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .OurHistory_cookiesLayer__2bH6n .OurHistory_snackbar__21Gzj {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .OurHistory_cookiesLayer__2bH6n .OurHistory_snackbar__21Gzj {
        width: 75%;
        padding: 2rem; } }
    .OurHistory_cookiesLayer__2bH6n .OurHistory_snackbar__21Gzj .OurHistory_moreInfoCookieText__1IVP0 {
      color: white;
      margin: 5px; }
    .OurHistory_cookiesLayer__2bH6n .OurHistory_snackbar__21Gzj button {
      background-color: transparent;
      color: white;
      border: none; }

.OurHistory_finalImgContainer__kizdZ {
  position: relative;
  margin: 0; }
  .OurHistory_finalImgContainer__kizdZ .OurHistory_asterisco__3GLoM {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .OurHistory_finalImgContainer__kizdZ .OurHistory_asterisco__3GLoM {
        right: 41%; } }

.OurHistory_cookiesMoreInfoButton__FuV8Q {
  background-color: #50a7b4 !important; }

.OurHistory_cookiesMoreInfoButton__FuV8Q:hover {
  background-color: #448086 !important; }

.OurHistory_privacy__2ehd4 {
  cursor: pointer; }

.OurHistory_privacy__2ehd4:hover {
  text-decoration: underline; }

.OurHistory_shareButtonsBox__1ESZ2 {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.OurHistory_shareButton__3hyEd:hover {
  cursor: pointer; }

.OurHistory_floatingButtonGetIt__NmV2m, .OurHistory_floatingButtonGetItLogged__2b_uN {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .OurHistory_floatingButtonGetIt__NmV2m, .OurHistory_floatingButtonGetItLogged__2b_uN {
      display: none !important; } }

.OurHistory_floatingButtonGetItLogged__2b_uN {
  bottom: 5em !important; }

.OurHistory_floatingButtonGetItIcon__mLbI0 {
  margin-right: 0.3em; }

.OurHistory_companiesTrustContainer__1buVw {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .OurHistory_companiesTrustContainer__1buVw {
      padding-bottom: 5em;
      flex-direction: column; } }
  .OurHistory_companiesTrustContainer__1buVw .OurHistory_companiesTrustContent__2dT01 {
    display: flex;
    align-items: center; }
  .OurHistory_companiesTrustContainer__1buVw .OurHistory_companiesTrustLogoEnisa__3yXKL {
    width: 150px;
    margin-right: 1.25rem;
    height: auto; }
    @media (max-width: 480px) {
      .OurHistory_companiesTrustContainer__1buVw .OurHistory_companiesTrustLogoEnisa__3yXKL {
        max-height: 8rem;
        margin-right: 0.5rem;
        width: 75px; } }
  .OurHistory_companiesTrustContainer__1buVw .OurHistory_companiesTrustLogo__2vaRD {
    width: 190px;
    height: auto; }
    @media (max-width: 480px) {
      .OurHistory_companiesTrustContainer__1buVw .OurHistory_companiesTrustLogo__2vaRD {
        max-height: 8rem;
        width: 135px; } }

.OurHistory_companiesTrustClaim__35447 {
  color: #ffffff !important;
  font-size: 80px !important;
  text-align: center !important;
  padding-bottom: 6rem !important;
  margin-top: 1rem !important;
  min-width: 75%;
  max-width: 90%;
  border-bottom: 0.25rem solid #fff; }
  @media (max-width: 480px) {
    .OurHistory_companiesTrustClaim__35447 {
      min-width: 90% !important;
      max-width: 90% !important;
      font-size: 30px !important;
      padding-bottom: 2.25rem !important;
      margin-bottom: 1rem !important; } }

.OurHistory_floatingActiveBraceletButton__2vgBB {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.OurHistory_activeIcon__25DuT {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.OurHistory_FbLikeButtonContainer__pPRFo {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .OurHistory_FbLikeButtonContainer__pPRFo {
      padding-top: 3em; } }

.OurHistory_facebookLikeButton__3Eb4w {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .OurHistory_facebookLikeButton__3Eb4w {
      display: none !important; } }
  @media (min-width: 768px) {
    .OurHistory_facebookLikeButton__3Eb4w {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.OurHistory_facebookLike__8cw6K {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .OurHistory_facebookLike__8cw6K {
      height: 45px;
      width: 45px; } }

.OurHistory_pulsidHeadImage__JhDkA {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .OurHistory_pulsidHeadImage__JhDkA {
      display: block; } }

.OurHistory_pulsidPriceBadgeDesktop__30bHQ {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .OurHistory_pulsidPriceBadgeDesktop__30bHQ {
      display: none !important; } }

.OurHistory_pulsidPriceBadgeAlways__38vDS {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.OurHistory_pulsidPriceBadge__38F0e {
  display: none !important; }
  @media (max-width: 768px) {
    .OurHistory_pulsidPriceBadge__38F0e {
      display: block !important; } }
  .OurHistory_pulsidPriceBadge__38F0e span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.OurHistory_discountSticker__12af1 {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.OurHistory_offerSnackbar__Q62TK {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .OurHistory_offerSnackbar__Q62TK div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.OurHistory_snackOfferButton__1tUqQ {
  background-color: #448086;
  color: #fff8e3; }

.OurHistory_offerBanner__2Vk7J {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .OurHistory_offerBanner__2Vk7J {
      flex-direction: column;
      display: none; } }

.OurHistory_offerBannerMobile__3Q6Z- {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .OurHistory_offerBannerMobile__3Q6Z- {
      display: flex;
      justify-content: space-around; } }

.OurHistory_closeOfferBanner__2wRNW {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .OurHistory_closeOfferBanner__2wRNW {
      position: absolute;
      right: 5px;
      top: 5px; } }

.OurHistory_offerBannerCountdown__75_f2 {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OurHistory_offerBannerCountdown__75_f2 {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .OurHistory_offerBannerCountdown__75_f2, .OurHistory_offerClaim__3Dk6p {
    display: flex;
    flex-direction: column; } }

.OurHistory_offerClaim__3Dk6p span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .OurHistory_offerClaim__3Dk6p span:first-child {
      display: inline-flex !important; } }

.OurHistory_offerButtonBox__3AHOC {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OurHistory_offerButtonBox__3AHOC {
      margin-top: 1em !important; } }

.OurHistory_offerBannerGetItButton__1EGAN {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.OurHistory_closeOfferBannerIcon__1owgU {
  color: #ffffff; }

.OurHistory_whatsappPostSection__5_ZzQ {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.OurHistory_whatsappPostClaim__2EZ2z {
  text-align: center !important; }

.OurHistory_whatsappPostItem__2h8A6 {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .OurHistory_whatsappPostItem__2h8A6 .OurHistory_downloadDocumentButton__1x-Xf {
    background-color: #fff8e3; }

.OurHistory_whatsappPostImage__1bPKF {
  height: 250px; }

.OurHistory_newsletterSectionGrid__2dJKB {
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: -4px;
  margin-bottom: -4px;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .OurHistory_newsletterSectionGrid__2dJKB {
      background-color: #50a7b4;
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.OurHistory_freeShippingClaim___g_tO {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Roboto Bold', sans-serif;
  padding-left: 0.4rem;
  padding-right: 1.2rem; }
  .OurHistory_freeShippingClaim___g_tO .OurHistory_countDown__3jwDy {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 17px; }
    @media (min-width: 768px) {
      .OurHistory_freeShippingClaim___g_tO .OurHistory_countDown__3jwDy {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 30px; } }
  .OurHistory_freeShippingClaim___g_tO span {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 20px; }
    @media (min-width: 768px) {
      .OurHistory_freeShippingClaim___g_tO span {
        font-size: 30px; } }

.OurHistory_CTAContainer__3O39M {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem; }
  .OurHistory_CTAContainer__3O39M .OurHistory_tapIcon__swiHC {
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: rotate(-25deg); }
  .OurHistory_CTAContainer__3O39M .OurHistory_primaryCTAButton__V6akh {
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .OurHistory_CTAContainer__3O39M .OurHistory_primaryCTAButton__V6akh {
        font-size: 20px; } }
  .OurHistory_CTAContainer__3O39M .OurHistory_primaryCTAButton__V6akh:hover {
    background-color: #ea605d; }
  .OurHistory_CTAContainer__3O39M .OurHistory_secondaryCTAButton__1FZYP {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .OurHistory_CTAContainer__3O39M .OurHistory_secondaryCTAButton__1FZYP {
        font-size: 20px !important; } }

.OurHistory_buyedClaimContainer__3-8CJ {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem !important; }
  @media (min-width: 1024px) {
    .OurHistory_buyedClaimContainer__3-8CJ {
      margin-bottom: 1.25rem !important; } }

.OurHistory_buyedClaimBox__JwojD, .OurHistory_buyNowClaimBox__rI2DV {
  width: 100%;
  height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  font-size: 17px;
  text-align: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .OurHistory_buyedClaimBox__JwojD, .OurHistory_buyNowClaimBox__rI2DV {
      font-size: 20px;
      margin-right: -1rem;
      margin-left: -1rem; } }

.OurHistory_buyNowClaimBox__rI2DV {
  background-color: #FF6966; }

.OurHistory_countUp__25u2_ {
  margin-top: 3rem !important;
  color: #fff;
  font-size: 70px !important;
  text-align: center; }
  @media (min-width: 768px) {
    .OurHistory_countUp__25u2_ {
      margin-top: 6rem !important; } }

.OurHistory_footerVideo__1PsSv {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem; }
  @media (min-width: 1024px) {
    .OurHistory_footerVideo__1PsSv {
      width: 50%;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.13); } }

.OurHistory_navidadCollage__3wiB- {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .OurHistory_navidadCollage__3wiB- {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes WeAreEco_floatBracelet__16wSZ {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes WeAreEco_floatVerticalBracelet__C1OHf {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes WeAreEco_backgroundGradient__3Inxg {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes WeAreEco_backgroundGradient2__3MsQw {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.WeAreEco_Landing__3E3-u {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #ffffff; }
  .WeAreEco_Landing__3E3-u h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .WeAreEco_Landing__3E3-u h2 {
    color: white;
    text-align: center;
    font-size: 30px; }
    @media (max-width: 768px) {
      .WeAreEco_Landing__3E3-u h2 {
        margin-top: 0;
        font-size: 20px; } }
  .WeAreEco_Landing__3E3-u h3 {
    font-family: Blogger-sans; }
  .WeAreEco_Landing__3E3-u li {
    font-family: Blogger-sans; }
  .WeAreEco_Landing__3E3-u a {
    font-family: Blogger-sans; }
  .WeAreEco_Landing__3E3-u p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    padding-top: 8rem;
    background: linear-gradient(to bottom, #06beb6, #74a7b2);
    margin-bottom: 5rem; }
    @media (max-width: 480px) {
      .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 {
        padding: 0;
        padding-top: 3rem;
        margin-bottom: 3rem;
        flex-direction: column; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyContainerContent__2CbHt {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyContainerContent__2CbHt h1, .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyContainerContent__2CbHt h3, .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyContainerContent__2CbHt p {
        color: #fff; }
      @media (max-width: 768px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyContainerContent__2CbHt {
          padding-right: 2rem;
          padding-left: 2rem; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyImageContainer__3ev4K {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-start; }
      .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyImageContainer__3ev4K img {
        height: auto;
        width: 100%; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_historyContainer__23f72 .WeAreEco_historyImageContainer__3ev4K img {
            width: 75%;
            border-radius: 25px; } }
  .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 {
        padding-top: 40px; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentContainerContent__ICFT5 {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentContainerContent__ICFT5 {
          flex-direction: column-reverse; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentTextContainer__flwIX {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentTextContainer__flwIX {
          max-width: 100%; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentTextContainer__flwIX h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentTextContainer__flwIX p {
          max-width: 70%; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentImageContainer__COHQ0 {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentImageContainer__COHQ0 {
          margin-left: 15px; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentImageContainer__COHQ0 img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_enviromentContainer__2K9y6 .WeAreEco_enviromentImageContainer__COHQ0 img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 0.25rem;
    flex-direction: row; }
    @media (max-width: 480px) {
      .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I {
        flex-direction: column; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA {
      display: flex;
      justify-content: center;
      position: relative; }
      .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_sampleImg__2ESZq {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_sampleImg__2ESZq {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_sampleImg__2ESZq {
            height: 500px;
            padding-right: 4em; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_mockStepsView__2r_wI {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_mockStepsView__2r_wI {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_mockStepsView__2r_wI {
            height: 450px;
            margin-right: -2.50em; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_mockContainerImg__3viLW {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_mockContainerImg__3viLW {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentMock__2GHmA .WeAreEco_mockContainerImg__3viLW {
            left: 0; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 {
          margin-right: 20px;
          margin-left: 20px; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 h1 {
            padding-left: 66px; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 .WeAreEco_content__31kDY {
        display: flex;
        justify-content: space-around; }
      .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 .WeAreEco_sampleList__2WAJ7 {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 .WeAreEco_sampleList__2WAJ7 {
            font-size: 17px;
            padding-left: 0; } }
        .WeAreEco_Landing__3E3-u .WeAreEco_sampleContainer__3071I .WeAreEco_contentList__318G7 .WeAreEco_sampleList__2WAJ7 li {
          display: flex;
          align-items: center; }
  .WeAreEco_Landing__3E3-u .WeAreEco_rateUsContainer__3icZo {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-bottom: -2px; }
    @media (min-width: 1024px) {
      .WeAreEco_Landing__3E3-u .WeAreEco_rateUsContainer__3icZo {
        padding-top: 3rem;
        padding-bottom: 4rem; } }
  .WeAreEco_Landing__3E3-u .WeAreEco_rateUsCopy__XHozA {
    margin: 2rem; }
  .WeAreEco_Landing__3E3-u .WeAreEco_rateIconItem__3ZC1t {
    display: flex;
    justify-content: center; }
    .WeAreEco_Landing__3E3-u .WeAreEco_rateIconItem__3ZC1t .WeAreEco_rateIconButton__3glIp {
      background-color: rgba(255, 255, 255, 0.89); }
    .WeAreEco_Landing__3E3-u .WeAreEco_rateIconItem__3ZC1t .WeAreEco_rateIcon__3OTFO {
      width: 3.5rem; }
  .WeAreEco_Landing__3E3-u .WeAreEco_reviewsContainer__2eRxH {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 4rem;
    margin-bottom: -2px; }
  .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 {
    margin: 1rem; }
    .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewHead__3Wm0B {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff; }
      .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewLogo__3niV8 {
        width: 150px;
        height: 150px;
        object-fit: cover;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewLogo__3niV8 {
            width: 80px;
            height: 80px; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewDataContainer__KTJGc {
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewDataContainer__KTJGc {
            max-width: 500px;
            margin-top: 20px;
            text-align: justify; } }
        .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewDataContainer__KTJGc .WeAreEco_reviewTitle__WRZUp {
          margin-top: 1rem;
          color: #ffffff;
          text-align: center; }
        .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewDataContainer__KTJGc .WeAreEco_starsContainer__3I3Mi {
          display: flex;
          align-items: center; }
        .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewDataContainer__KTJGc a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewDataContainer__KTJGc p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_reviewGridItem__1pLf9 .WeAreEco_reviewContainerContent__2_Mof .WeAreEco_reviewDataContainer__KTJGc h6 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%; }
    .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      padding-bottom: 3rem;
      min-height: 20rem;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column;
          min-height: 27rem; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb .WeAreEco_newsLogo__viS64 {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb .WeAreEco_newsLogo__viS64 {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb .WeAreEco_newsDataContainer__2m1LL {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb .WeAreEco_newsDataContainer__2m1LL {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb .WeAreEco_newsDataContainer__2m1LL a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb .WeAreEco_newsDataContainer__2m1LL p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3E3-u .WeAreEco_newsContainer__2NAgK .WeAreEco_newsContainerContent__2nNOb .WeAreEco_newsDataContainer__2m1LL h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf {
        align-items: center; } }
    .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_sevi__3MIq1 {
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_codeModal__1MgLp {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN {
          flex-direction: column; } }
      .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN .WeAreEco_footerLawsContainer__1L3-8 {
        display: flex;
        margin: 0.5rem; }
        .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN .WeAreEco_footerLawsContainer__1L3-8 p {
          margin: 0.25rem;
          font-size: 15px;
          color: #716b77; }
      .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN .WeAreEco_seviLogo__3373k {
        width: 150px; }
      .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN .WeAreEco_footerLogosContainer__2ybJU {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25rem;
        margin-top: 2rem; }
      .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN .WeAreEco_footerContentServices__k8MVL {
        display: flex;
        flex-direction: column; }
        .WeAreEco_Landing__3E3-u .WeAreEco_footerContainer__18QQf .WeAreEco_footerContent__3UgwN .WeAreEco_footerContentServices__k8MVL div {
          max-width: 400px; }

.WeAreEco_modalInfoHeader__2VIIU {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.WeAreEco_modalInfoContent__2HHI- {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .WeAreEco_modalInfoContent__2HHI- {
      flex-direction: column; } }
  .WeAreEco_modalInfoContent__2HHI- li {
    font-size: 20px;
    margin-top: 10px; }
  .WeAreEco_modalInfoContent__2HHI- .WeAreEco_imageHand__2qbrB {
    width: 15rem;
    height: 15rem; }
  .WeAreEco_modalInfoContent__2HHI- .WeAreEco_imageKid__7ZPgx {
    width: 11rem;
    height: 22rem; }

.WeAreEco_seeMoreText__DiZHY {
  text-decoration: underline; }

.WeAreEco_cookiesLayer__1Nrw_ {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .WeAreEco_cookiesLayer__1Nrw_ .WeAreEco_snackbar__3F3P2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .WeAreEco_cookiesLayer__1Nrw_ .WeAreEco_snackbar__3F3P2 {
        width: 75%;
        padding: 2rem; } }
    .WeAreEco_cookiesLayer__1Nrw_ .WeAreEco_snackbar__3F3P2 .WeAreEco_moreInfoCookieText__3JyfL {
      color: white;
      margin: 5px; }
    .WeAreEco_cookiesLayer__1Nrw_ .WeAreEco_snackbar__3F3P2 button {
      background-color: transparent;
      color: white;
      border: none; }

.WeAreEco_finalImgContainer__5Scew {
  position: relative;
  margin: 0; }
  .WeAreEco_finalImgContainer__5Scew .WeAreEco_asterisco__MqqmL {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .WeAreEco_finalImgContainer__5Scew .WeAreEco_asterisco__MqqmL {
        right: 41%; } }

.WeAreEco_cookiesMoreInfoButton__30uku {
  background-color: #50a7b4 !important; }

.WeAreEco_cookiesMoreInfoButton__30uku:hover {
  background-color: #448086 !important; }

.WeAreEco_privacy__yBXxE {
  cursor: pointer; }

.WeAreEco_privacy__yBXxE:hover {
  text-decoration: underline; }

.WeAreEco_shareButtonsBox__3Ljd- {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.WeAreEco_shareButton__149_7:hover {
  cursor: pointer; }

.WeAreEco_floatingButtonGetIt__2zgS5, .WeAreEco_floatingButtonGetItLogged__3xuFd {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .WeAreEco_floatingButtonGetIt__2zgS5, .WeAreEco_floatingButtonGetItLogged__3xuFd {
      display: none !important; } }

.WeAreEco_floatingButtonGetItLogged__3xuFd {
  bottom: 5em !important; }

.WeAreEco_floatingButtonGetItIcon__3oaBS {
  margin-right: 0.3em; }

.WeAreEco_companiesTrustContainer__In1VY {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .WeAreEco_companiesTrustContainer__In1VY {
      padding-bottom: 5em;
      flex-direction: column; } }
  .WeAreEco_companiesTrustContainer__In1VY .WeAreEco_companiesTrustContent__2ZA89 {
    display: flex;
    align-items: center; }
  .WeAreEco_companiesTrustContainer__In1VY .WeAreEco_companiesTrustLogoEnisa__3hqJ7 {
    width: 150px;
    margin-right: 1.25rem;
    height: auto; }
    @media (max-width: 480px) {
      .WeAreEco_companiesTrustContainer__In1VY .WeAreEco_companiesTrustLogoEnisa__3hqJ7 {
        max-height: 8rem;
        margin-right: 0.5rem;
        width: 75px; } }
  .WeAreEco_companiesTrustContainer__In1VY .WeAreEco_companiesTrustLogo__3QH5n {
    width: 190px;
    height: auto; }
    @media (max-width: 480px) {
      .WeAreEco_companiesTrustContainer__In1VY .WeAreEco_companiesTrustLogo__3QH5n {
        max-height: 8rem;
        width: 135px; } }

.WeAreEco_companiesTrustClaim__34axP {
  color: #ffffff !important;
  font-size: 80px !important;
  text-align: center !important;
  padding-bottom: 6rem !important;
  margin-top: 1rem !important;
  min-width: 75%;
  max-width: 90%;
  border-bottom: 0.25rem solid #fff; }
  @media (max-width: 480px) {
    .WeAreEco_companiesTrustClaim__34axP {
      min-width: 90% !important;
      max-width: 90% !important;
      font-size: 30px !important;
      padding-bottom: 2.25rem !important;
      margin-bottom: 1rem !important; } }

.WeAreEco_floatingActiveBraceletButton__3-t5_ {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.WeAreEco_activeIcon__1f_S6 {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.WeAreEco_FbLikeButtonContainer__3Axb5 {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .WeAreEco_FbLikeButtonContainer__3Axb5 {
      padding-top: 3em; } }

.WeAreEco_facebookLikeButton__36AkZ {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .WeAreEco_facebookLikeButton__36AkZ {
      display: none !important; } }
  @media (min-width: 768px) {
    .WeAreEco_facebookLikeButton__36AkZ {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.WeAreEco_facebookLike__1j9Wr {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .WeAreEco_facebookLike__1j9Wr {
      height: 45px;
      width: 45px; } }

.WeAreEco_pulsidHeadImage__cvrAj {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .WeAreEco_pulsidHeadImage__cvrAj {
      display: block; } }

.WeAreEco_pulsidPriceBadgeDesktop__3Xild {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .WeAreEco_pulsidPriceBadgeDesktop__3Xild {
      display: none !important; } }

.WeAreEco_pulsidPriceBadgeAlways__2ig8c {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.WeAreEco_pulsidPriceBadge__3O4Ro {
  display: none !important; }
  @media (max-width: 768px) {
    .WeAreEco_pulsidPriceBadge__3O4Ro {
      display: block !important; } }
  .WeAreEco_pulsidPriceBadge__3O4Ro span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.WeAreEco_discountSticker__3J9WN {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.WeAreEco_offerSnackbar__3lCaV {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .WeAreEco_offerSnackbar__3lCaV div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.WeAreEco_snackOfferButton__2RPZY {
  background-color: #448086;
  color: #fff8e3; }

.WeAreEco_offerBanner__23i1N {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .WeAreEco_offerBanner__23i1N {
      flex-direction: column;
      display: none; } }

.WeAreEco_offerBannerMobile__1dobA {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .WeAreEco_offerBannerMobile__1dobA {
      display: flex;
      justify-content: space-around; } }

.WeAreEco_closeOfferBanner__qsttT {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .WeAreEco_closeOfferBanner__qsttT {
      position: absolute;
      right: 5px;
      top: 5px; } }

.WeAreEco_offerBannerCountdown__2EVaj {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .WeAreEco_offerBannerCountdown__2EVaj {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .WeAreEco_offerBannerCountdown__2EVaj, .WeAreEco_offerClaim__dczVS {
    display: flex;
    flex-direction: column; } }

.WeAreEco_offerClaim__dczVS span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .WeAreEco_offerClaim__dczVS span:first-child {
      display: inline-flex !important; } }

.WeAreEco_offerButtonBox__3X3i3 {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .WeAreEco_offerButtonBox__3X3i3 {
      margin-top: 1em !important; } }

.WeAreEco_offerBannerGetItButton__Wcmfc {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.WeAreEco_closeOfferBannerIcon__1ETDk {
  color: #ffffff; }

.WeAreEco_whatsappPostSection__3yCux {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.WeAreEco_whatsappPostClaim__1kvzW {
  text-align: center !important; }

.WeAreEco_whatsappPostItem__r0SsQ {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .WeAreEco_whatsappPostItem__r0SsQ .WeAreEco_downloadDocumentButton__3e7hc {
    background-color: #fff8e3; }

.WeAreEco_whatsappPostImage__UVeMf {
  height: 250px; }

.WeAreEco_newsletterSectionGrid__1c1nH {
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: -4px;
  margin-bottom: -4px;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .WeAreEco_newsletterSectionGrid__1c1nH {
      background-color: #4FA9B3;
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.WeAreEco_freeShippingClaim__27eSU {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Roboto Bold', sans-serif;
  padding-left: 0.4rem;
  padding-right: 1.2rem; }
  .WeAreEco_freeShippingClaim__27eSU .WeAreEco_countDown__2VuNf {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 17px; }
    @media (min-width: 768px) {
      .WeAreEco_freeShippingClaim__27eSU .WeAreEco_countDown__2VuNf {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 30px; } }
  .WeAreEco_freeShippingClaim__27eSU span {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 20px; }
    @media (min-width: 768px) {
      .WeAreEco_freeShippingClaim__27eSU span {
        font-size: 30px; } }

.WeAreEco_CTAContainer__3e3cw {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 4rem; }
  .WeAreEco_CTAContainer__3e3cw .WeAreEco_tapIcon__2OfD8 {
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: rotate(-25deg); }
  .WeAreEco_CTAContainer__3e3cw .WeAreEco_primaryCTAButton__37REJ {
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .WeAreEco_CTAContainer__3e3cw .WeAreEco_primaryCTAButton__37REJ {
        font-size: 20px; } }
  .WeAreEco_CTAContainer__3e3cw .WeAreEco_primaryCTAButton__37REJ:hover {
    background-color: #ea605d; }
  .WeAreEco_CTAContainer__3e3cw .WeAreEco_secondaryCTAButton__1_DXk {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .WeAreEco_CTAContainer__3e3cw .WeAreEco_secondaryCTAButton__1_DXk {
        font-size: 20px !important; } }

.WeAreEco_buyedClaimContainer__HUSv8 {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem !important; }
  @media (min-width: 1024px) {
    .WeAreEco_buyedClaimContainer__HUSv8 {
      margin-bottom: 1.25rem !important; } }

.WeAreEco_buyedClaimBox__1vWHp, .WeAreEco_buyNowClaimBox__2YgSD {
  width: 100%;
  height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  font-size: 17px;
  text-align: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .WeAreEco_buyedClaimBox__1vWHp, .WeAreEco_buyNowClaimBox__2YgSD {
      font-size: 20px;
      margin-right: -1rem;
      margin-left: -1rem; } }

.WeAreEco_buyNowClaimBox__2YgSD {
  background-color: #FF6966; }

.WeAreEco_countUp__1y7oR {
  margin-top: 3rem !important;
  color: #fff;
  font-size: 70px !important;
  text-align: center; }
  @media (min-width: 768px) {
    .WeAreEco_countUp__1y7oR {
      margin-top: 6rem !important; } }

.WeAreEco_footerVideo__3HA9Z {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem; }
  @media (min-width: 1024px) {
    .WeAreEco_footerVideo__3HA9Z {
      width: 50%;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.13); } }

.WeAreEco_navidadCollage__3ggY4 {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .WeAreEco_navidadCollage__3ggY4 {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes OldLanding_floatBracelet__2RffJ {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes OldLanding_floatVerticalBracelet__2Wbdh {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes OldLanding_backgroundGradient__iO5oJ {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes OldLanding_backgroundGradient2__28uC2 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.OldLanding_Landing__3SvNO {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #fff8e3; }
  .OldLanding_Landing__3SvNO h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .OldLanding_Landing__3SvNO h2 {
    color: white;
    text-align: center;
    font-size: 30px;
    margin-top: -30px; }
    @media (max-width: 768px) {
      .OldLanding_Landing__3SvNO h2 {
        margin-top: 0;
        font-size: 20px; } }
  .OldLanding_Landing__3SvNO h3 {
    font-family: Blogger-sans; }
  .OldLanding_Landing__3SvNO li {
    font-family: Blogger-sans; }
  .OldLanding_Landing__3SvNO a {
    font-family: Blogger-sans; }
  .OldLanding_Landing__3SvNO p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap !important;
    width: 100%;
    min-height: 100vh;
    padding-top: 1em;
    align-items: center;
    justify-content: center;
    background: url(/static/media/bg-masthead-blur2.5bb4617d.png) no-repeat center;
    background-size: cover; }
    @media (max-width: 768px) {
      .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 {
        background: url(/static/media/bg-masthead-blur.06a6ea49.png) no-repeat center;
        background-size: cover; } }
    @media (max-width: 360px) {
      .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 {
        padding-top: 3em !important;
        padding-bottom: 3em !important; } }
    .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 h1 {
      font-size: 80px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 h1 {
          font-size: 40px; } }
    .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 .OldLanding_primaryCTAButton__1YUDu {
      color: #fff8e3;
      border: 2px solid;
      padding: 1em 2.5em;
      border-radius: 10px;
      cursor: pointer;
      font-size: 30px; }
      @media (max-width: 480px) {
        .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 .OldLanding_primaryCTAButton__1YUDu {
          font-size: 20px; } }
    @media (min-width: 1024px) {
      .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 .OldLanding_headContainerImg__2lpGm {
        margin-top: -200px; } }
    .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 .OldLanding_headContainerContent__1gMQZ {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 80%;
      padding-top: 1em; }
      .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 .OldLanding_headContainerContent__1gMQZ .OldLanding_buy24Container__1LGFs {
        background-color: #fff8e3;
        color: #50a7b4;
        font-size: 17px;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 4rem;
        border-radius: 4px; }
        @media (max-width: 480px) {
          .OldLanding_Landing__3SvNO .OldLanding_headContainer__1pby2 .OldLanding_headContainerContent__1gMQZ .OldLanding_buy24Container__1LGFs {
            margin-top: 2rem;
            width: 100%; } }
  .OldLanding_Landing__3SvNO .OldLanding_weHelpYouSection__20cih {
    display: flex;
    justify-content: space-around;
    background-color: #74a7b1;
    width: 100%;
    margin-bottom: -2px; }
    .OldLanding_Landing__3SvNO .OldLanding_weHelpYouSection__20cih p {
      text-align: center;
      color: #ffffff; }
    .OldLanding_Landing__3SvNO .OldLanding_weHelpYouSection__20cih .OldLanding_documentsButtonContainer__1Of0_ {
      margin-top: 1em;
      display: flex !important;
      justify-content: center !important; }
      .OldLanding_Landing__3SvNO .OldLanding_weHelpYouSection__20cih .OldLanding_documentsButtonContainer__1Of0_ .OldLanding_downloadDocumentButton__jtr63 {
        background-color: #fff8e3 !important; }
      .OldLanding_Landing__3SvNO .OldLanding_weHelpYouSection__20cih .OldLanding_documentsButtonContainer__1Of0_ .OldLanding_documentsDownloadIcon__M7iT2 {
        margin-right: 0.2em; }
  .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    color: #fff8e3;
    position: relative; }
    .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V p {
      max-width: 800px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V p {
          max-width: 400px; } }
    .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_fondoOlasImgs__2_75Y {
      position: absolute; }
    .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_peopleContainerContent__2sTP4 {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 1rem;
      max-width: 90vw; }
    .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_peopleIconContainer__3DotT {
      display: flex; }
      .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_peopleIconContainer__3DotT .OldLanding_peopleIconContent__2jk-Q {
        display: flex;
        flex-direction: column;
        align-items: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_peopleIconContainer__3DotT p {
          text-align: center; } }
      .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_peopleIconContainer__3DotT .OldLanding_peopleIconContent__2jk-Q:hover {
        cursor: pointer; }
      .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_peopleIconContainer__3DotT .OldLanding_peopleIconLeft__3oQfO {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 50px; }
      .OldLanding_Landing__3SvNO .OldLanding_peopleContainer__vr5-V .OldLanding_peopleIconContainer__3DotT .OldLanding_peopleIconRigth__dkiqP {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
  .OldLanding_Landing__3SvNO .OldLanding_whyContainer__15wa6 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .OldLanding_Landing__3SvNO .OldLanding_whyContainer__15wa6 h1 {
      color: #50a7b4; }
    .OldLanding_Landing__3SvNO .OldLanding_whyContainer__15wa6 .OldLanding_whyList__16p0G {
      list-style-type: none;
      text-align: left;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: bold; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_whyContainer__15wa6 .OldLanding_whyList__16p0G {
          font-size: 17px;
          padding-left: 0;
          padding-right: 20px;
          margin-left: -20px; } }
      .OldLanding_Landing__3SvNO .OldLanding_whyContainer__15wa6 .OldLanding_whyList__16p0G li {
        display: flex;
        align-items: center; }
    .OldLanding_Landing__3SvNO .OldLanding_whyContainer__15wa6 .OldLanding_whyArrowImg__fiL4I {
      transform: rotate(45deg); }
  .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%; }
    .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks {
      display: flex;
      align-items: center; }
      .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks .OldLanding_braceletsContainerImgBracelet__MMQ5B {
        animation: OldLanding_floatBracelet__2RffJ 6s ease-in-out infinite;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 250px; }
        @media (max-width: 480px) {
          .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks .OldLanding_braceletsContainerImgBracelet__MMQ5B {
            margin-bottom: 50px; } }
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks .OldLanding_braceletsContainerImgBracelet__MMQ5B img {
          height: 160px; }
          @media (max-width: 768px) {
            .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks .OldLanding_braceletsContainerImgBracelet__MMQ5B img {
              width: 250px; } }
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks .OldLanding_braceletsContainerImgBracelet__MMQ5B p {
          color: #448086;
          font-size: 30px; }
      .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks .OldLanding_braceletsContainerImgHandQR__1jJQO {
        width: 180px;
        transform: rotate(-10deg); }
        @media (max-width: 480px) {
          .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImg__2gTks .OldLanding_braceletsContainerImgHandQR__1jJQO {
            display: none; } }
    .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImgArrowLeft__1HDJk {
      margin-top: -200px;
      margin-left: 70px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImgArrowLeft__1HDJk {
          display: none; } }
    .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImgArrowMobile__2gsOM {
      margin-top: -90px;
      margin-left: 70px; }
      @media (min-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_braceletsContainerImgArrowMobile__2gsOM {
          display: none; } }
    .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_content__3l_FK {
      display: flex;
      justify-content: space-around;
      align-items: center; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_content__3l_FK {
          flex-direction: column;
          align-items: center; } }
    .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_contentVideo__2rbbE {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_contentVideo__2rbbE {
          flex-direction: column-reverse;
          align-items: center; } }
    .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_ownBraceletsTextContainer__2nHYd {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 600px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_ownBraceletsTextContainer__2nHYd {
          padding: 0 20px; } }
      .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_ownBraceletsTextContainer__2nHYd h1 {
        color: #50a7b4; }
    .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_iframeVideo__1U5E_ {
      width: 600px;
      height: 350px; }
      @media (max-width: 480px) {
        .OldLanding_Landing__3SvNO .OldLanding_ownBraceletsContainer__11jaG .OldLanding_iframeVideo__1U5E_ {
          width: 85%;
          height: 200px;
          margin-bottom: 20px; } }
  .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG {
    display: flex;
    background-color: #efe9d6; }
    @media (max-width: 480px) {
      .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG {
        flex-direction: column; } }
    .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyContainerContent__vS2Yb {
      max-width: 65%;
      padding: 10px 60px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyContainerContent__vS2Yb {
          max-width: 100%;
          padding: 0 20px; } }
      .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyContainerContent__vS2Yb h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyContainerContent__vS2Yb p {
          max-width: 65%; } }
    .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyImageContainer__2qu64 {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyImageContainer__2qu64 {
          margin-right: 15px; } }
      .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyImageContainer__2qu64 img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_historyContainer__2G9IG .OldLanding_historyImageContainer__2qu64 img {
            margin-top: 3em;
            margin-bottom: 3em;
            border-radius: 25px; } }
  .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 {
        padding-top: 40px; } }
    .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentContainerContent__2LkPS {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentContainerContent__2LkPS {
          flex-direction: column-reverse; } }
    .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentTextContainer__49FAq {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentTextContainer__49FAq {
          max-width: 100%; } }
      .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentTextContainer__49FAq h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentTextContainer__49FAq p {
          max-width: 70%; } }
    .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentImageContainer__1jKK_ {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentImageContainer__1jKK_ {
          margin-left: 15px; } }
      .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentImageContainer__1jKK_ img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_enviromentContainer__12cN7 .OldLanding_enviromentImageContainer__1jKK_ img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 60px;
    flex-direction: row; }
    @media (max-width: 480px) {
      .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF {
        flex-direction: column; } }
    .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB {
      display: flex;
      justify-content: center;
      position: relative; }
      .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_sampleImg__3Drsr {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_sampleImg__3Drsr {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_sampleImg__3Drsr {
            height: 500px;
            padding-right: 4em; } }
      .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_mockStepsView__3xBdM {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_mockStepsView__3xBdM {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_mockStepsView__3xBdM {
            height: 450px;
            margin-right: -2.50em; } }
      .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_mockContainerImg__3Hp_m {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_mockContainerImg__3Hp_m {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentMock__L4ACB .OldLanding_mockContainerImg__3Hp_m {
            left: 0; } }
    .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR {
          margin-right: 20px;
          margin-left: 20px; } }
      .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR h1 {
            padding-left: 66px; } }
      .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR .OldLanding_content__3l_FK {
        display: flex;
        justify-content: space-around; }
      .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR .OldLanding_sampleList__1jBaR {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR .OldLanding_sampleList__1jBaR {
            font-size: 17px;
            padding-left: 0; } }
        .OldLanding_Landing__3SvNO .OldLanding_sampleContainer__3V3xF .OldLanding_contentList__ldYmR .OldLanding_sampleList__1jBaR li {
          display: flex;
          align-items: center; }
  .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR {
    display: flex;
    justify-content: center;
    width: 100%; }
    .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      height: 350px;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column; } }
      .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewLogo__I-az0 {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewLogo__I-az0 {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewDataContainer__14u1n {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewDataContainer__14u1n {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewDataContainer__14u1n .OldLanding_starsContainer__dEDU0 {
          display: flex;
          align-items: center; }
        .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewDataContainer__14u1n a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewDataContainer__14u1n p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_reviewsContainer__14cuR .OldLanding_reviewContainerContent__3B14Z .OldLanding_reviewDataContainer__14u1n h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l {
    display: flex;
    justify-content: center;
    width: 100%; }
    .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      height: 350px;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column; } }
      .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R .OldLanding_newsLogo__1tR_M {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R .OldLanding_newsLogo__1tR_M {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R .OldLanding_newsDataContainer__3rm5X {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R .OldLanding_newsDataContainer__3rm5X {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R .OldLanding_newsDataContainer__3rm5X a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R .OldLanding_newsDataContainer__3rm5X p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .OldLanding_Landing__3SvNO .OldLanding_newsContainer__1cA7l .OldLanding_newsContainerContent__2ad8R .OldLanding_newsDataContainer__3rm5X h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX {
        align-items: center; } }
    .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_sevi__2u_hM {
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_codeModal__3WKMv {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_footerContent__mu0-4 {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_footerContent__mu0-4 {
          flex-direction: column; } }
      .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_footerContent__mu0-4 .OldLanding_footerLawsContainer__KnGFi {
        display: flex;
        width: 210px;
        justify-content: space-around; }
        .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_footerContent__mu0-4 .OldLanding_footerLawsContainer__KnGFi p {
          font-size: 15px;
          color: #716b77; }
      .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_footerContent__mu0-4 .OldLanding_seviLogo__2iMxB {
        width: 150px; }
      .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_footerContent__mu0-4 .OldLanding_footerContentServices__3Vp1C {
        display: flex;
        flex-direction: column; }
        .OldLanding_Landing__3SvNO .OldLanding_footerContainer__1HXgX .OldLanding_footerContent__mu0-4 .OldLanding_footerContentServices__3Vp1C div {
          max-width: 400px; }

.OldLanding_modalInfoHeader__2WtSc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.OldLanding_modalInfoContent__3MMtF {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .OldLanding_modalInfoContent__3MMtF {
      flex-direction: column; } }
  .OldLanding_modalInfoContent__3MMtF li {
    font-size: 20px;
    margin-top: 10px; }
  .OldLanding_modalInfoContent__3MMtF .OldLanding_imageHand__2NPHe {
    width: 15rem;
    height: 15rem; }
  .OldLanding_modalInfoContent__3MMtF .OldLanding_imageKid__fTxjP {
    width: 11rem;
    height: 22rem; }

.OldLanding_seeMoreText__2JA6e {
  text-decoration: underline; }

.OldLanding_cookiesLayer__2S7N4 {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .OldLanding_cookiesLayer__2S7N4 .OldLanding_snackbar__251vz {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .OldLanding_cookiesLayer__2S7N4 .OldLanding_snackbar__251vz {
        width: 75%;
        padding: 2rem; } }
    .OldLanding_cookiesLayer__2S7N4 .OldLanding_snackbar__251vz .OldLanding_moreInfoCookieText__3WKeC {
      color: white;
      margin: 5px; }
    .OldLanding_cookiesLayer__2S7N4 .OldLanding_snackbar__251vz button {
      background-color: transparent;
      color: white;
      border: none; }

.OldLanding_finalImgContainer__KO-Ce {
  position: relative;
  margin: 0; }
  .OldLanding_finalImgContainer__KO-Ce .OldLanding_asterisco__29bvz {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .OldLanding_finalImgContainer__KO-Ce .OldLanding_asterisco__29bvz {
        right: 41%; } }

.OldLanding_cookiesMoreInfoButton__Oy9L_ {
  background-color: #50a7b4 !important; }

.OldLanding_cookiesMoreInfoButton__Oy9L_:hover {
  background-color: #448086 !important; }

.OldLanding_privacy__3sev5 {
  cursor: pointer; }

.OldLanding_privacy__3sev5:hover {
  text-decoration: underline; }

.OldLanding_shareButtonsBox__1wdKl {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.OldLanding_shareButton__3VCKJ:hover {
  cursor: pointer; }

.OldLanding_floatingButtonGetIt__3ZXwM, .OldLanding_floatingButtonGetItLogged__1iw6h {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .OldLanding_floatingButtonGetIt__3ZXwM, .OldLanding_floatingButtonGetItLogged__1iw6h {
      display: none !important; } }

.OldLanding_floatingButtonGetItLogged__1iw6h {
  bottom: 5em !important; }

.OldLanding_floatingButtonGetItIcon__2HMRL {
  margin-right: 0.3em; }

.OldLanding_companiesTrustContainer__3tnwJ {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .OldLanding_companiesTrustContainer__3tnwJ {
      padding-bottom: 5em; } }
  .OldLanding_companiesTrustContainer__3tnwJ .OldLanding_companiesTrustContent__36J1Y {
    display: flex;
    align-items: center; }
  .OldLanding_companiesTrustContainer__3tnwJ .OldLanding_companiesTrustLogo__10v5b {
    width: 150px; }
    @media (max-width: 480px) {
      .OldLanding_companiesTrustContainer__3tnwJ .OldLanding_companiesTrustLogo__10v5b {
        width: 75px; } }

.OldLanding_companiesTrustClaim__13zGI {
  color: #ffffff !important;
  font-size: 20px !important;
  text-align: center !important; }
  @media (max-width: 480px) {
    .OldLanding_companiesTrustClaim__13zGI {
      font-size: 15px !important; } }

.OldLanding_floatingActiveBraceletButton__2Qh2h {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.OldLanding_activeIcon__R1TxD {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.OldLanding_FbLikeButtonContainer__c23Uo {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .OldLanding_FbLikeButtonContainer__c23Uo {
      padding-top: 3em; } }

.OldLanding_facebookLikeButton__l4Ib- {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .OldLanding_facebookLikeButton__l4Ib- {
      display: none !important; } }
  @media (min-width: 768px) {
    .OldLanding_facebookLikeButton__l4Ib- {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.OldLanding_facebookLike__M4xOg {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .OldLanding_facebookLike__M4xOg {
      height: 45px;
      width: 45px; } }

.OldLanding_pulsidHeadImage__3-A9e {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .OldLanding_pulsidHeadImage__3-A9e {
      display: block; } }

.OldLanding_pulsidPriceBadgeDesktop__1GwmN {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .OldLanding_pulsidPriceBadgeDesktop__1GwmN {
      display: none !important; } }

.OldLanding_pulsidPriceBadgeAlways__1HeA7 {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.OldLanding_pulsidPriceBadge__1xB8O {
  display: none !important; }
  @media (max-width: 768px) {
    .OldLanding_pulsidPriceBadge__1xB8O {
      display: block !important; } }
  .OldLanding_pulsidPriceBadge__1xB8O span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.OldLanding_discountSticker__m9wOC {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.OldLanding_offerSnackbar__2Jkg6 {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .OldLanding_offerSnackbar__2Jkg6 div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.OldLanding_snackOfferButton__32q0H {
  background-color: #448086;
  color: #fff8e3; }

.OldLanding_offerBanner__1uW6Z {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .OldLanding_offerBanner__1uW6Z {
      flex-direction: column;
      display: none; } }

.OldLanding_offerBannerMobile__xYFLj {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .OldLanding_offerBannerMobile__xYFLj {
      display: flex;
      justify-content: space-around; } }

.OldLanding_closeOfferBanner__OD1Ec {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .OldLanding_closeOfferBanner__OD1Ec {
      position: absolute;
      right: 5px;
      top: 5px; } }

.OldLanding_offerBannerCountdown__1w57D {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OldLanding_offerBannerCountdown__1w57D {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .OldLanding_offerBannerCountdown__1w57D, .OldLanding_offerClaim__yNXHb {
    display: flex;
    flex-direction: column; } }

.OldLanding_offerClaim__yNXHb span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .OldLanding_offerClaim__yNXHb span:first-child {
      display: inline-flex !important; } }

.OldLanding_offerButtonBox__3bL52 {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OldLanding_offerButtonBox__3bL52 {
      margin-top: 1em !important; } }

.OldLanding_offerBannerGetItButton__3tpPc {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.OldLanding_closeOfferBannerIcon__3DpG6 {
  color: #ffffff; }

.OldLanding_whatsappPostSection__3HsEv {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.OldLanding_whatsappPostClaim__1e4R1 {
  text-align: center !important; }

.OldLanding_whatsappPostItem__Wle7L {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .OldLanding_whatsappPostItem__Wle7L .OldLanding_downloadDocumentButton__jtr63 {
    background-color: #fff8e3; }

.OldLanding_whatsappPostImage__1UzE- {
  height: 250px; }

.OldLanding_newsletterSectionGrid__eoIgK {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: #fff8e3; }
  @media (min-width: 768px) {
    .OldLanding_newsletterSectionGrid__eoIgK {
      padding-top: 5rem;
      padding-bottom: 3.5rem; } }

.OldLanding_CTAContainer__1ki3l {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em !important; }
  .OldLanding_CTAContainer__1ki3l p {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    display: flex;
    align-items: center;
    font-size: 17px;
    background-color: #ffffff;
    border-radius: 8px;
    padding-left: 0.4rem;
    padding-right: 1.2rem; }
  .OldLanding_CTAContainer__1ki3l .OldLanding_secondaryCTAButton__1A1QZ {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .OldLanding_CTAContainer__1ki3l .OldLanding_secondaryCTAButton__1A1QZ {
        font-size: 20px !important; } }

.OldLanding_navidadCollage__3vT8l {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .OldLanding_navidadCollage__3vT8l {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.oldBuyModals_mainDialogContent__StMP8 {
  background: #f8f8f8; }
  @media (min-width: 1024px) {
    .oldBuyModals_mainDialogContent__StMP8 {
      border-radius: 8px;
      margin: 0 11rem;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; } }

.oldBuyModals_parentContainer__3pgum {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: inherit !important; }

.oldBuyModals_termsContainer__zYZ5i {
  display: flex;
  flex-direction: column;
  align-items: center; }

.oldBuyModals_formControl__3Sh_A {
  margin-top: 8px;
  min-width: 120px;
  display: flex !important; }
  .oldBuyModals_formControl__3Sh_A label {
    color: #50a7b4 !important; }
  .oldBuyModals_formControl__3Sh_A div fieldset {
    border-color: #50a7b4 !important; }
  .oldBuyModals_formControl__3Sh_A svg {
    color: #50a7b4 !important; }

.oldBuyModals_checkbox__1lpBT {
  color: #50a7b4 !important; }

.oldBuyModals_countryGridItem__yJcG_ {
  visibility: hidden; }
  .oldBuyModals_countryGridItem__yJcG_ .oldBuyModals_formRedsys__3FEkS {
    width: 100%; }

.oldBuyModals_cityZipcodeContainer__1IV30 {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.oldBuyModals_privacyLink__3xOny {
  color: #716b77; }

.oldBuyModals_privacyLink__3xOny:hover {
  color: #0077b5;
  text-decoration: underline; }

.oldBuyModals_closeModalIcon__5IbTX {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.oldBuyModals_fixModalPadding__2fAb3 {
  padding: 0 !important; }

.oldBuyModals_paymentModal__2LK8a {
  padding: 0 !important; }
  .oldBuyModals_paymentModal__2LK8a [class^="MuiPaper-root"] {
    min-width: 350px; }
  .oldBuyModals_paymentModal__2LK8a .oldBuyModals_paymentModalTitleBox__1K1BX {
    display: flex;
    align-items: center;
    margin-top: -20px; }

.oldBuyModals_sendingDataAnimation__3lGx9 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.oldBuyModals_textField__1URKz label {
  color: #50a7b4 !important; }

.oldBuyModals_textField__1URKz div fieldset {
  border-color: #50a7b4 !important; }

.oldBuyModals_textFieldError__cYKK0 label {
  color: #ff3d00 !important; }

.oldBuyModals_textFieldError__cYKK0 div fieldset {
  border-color: #ff3d00 !important; }

.oldBuyModals_contentText__HScyu {
  margin-bottom: 10px !important; }

.oldBuyModals_contentInnerText__28OeH {
  margin-left: 8px !important;
  font-size: 2.5rem !important;
  align-self: start !important; }

.oldBuyModals_formContainer__2N5li {
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.oldBuyModals_sizeCardContainer__2MajR {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-around; }
  @media (max-width: 480px) {
    .oldBuyModals_sizeCardContainer__2MajR {
      flex-direction: column-reverse;
      margin-top: 10px !important; } }
  .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    margin: 0.75em; }
    @media (max-width: 480px) {
      .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik {
        margin: 1.00em;
        padding: 1em; } }
    .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizeCardImg__1XEbu {
      width: 250px;
      margin: 1em !important; }
      @media (max-width: 480px) {
        .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizeCardImg__1XEbu {
          width: 200px; } }
    .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizeCardSize__3HL6u {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #448086; }
      .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizeCardSize__3HL6u .oldBuyModals_braceletSizeBox__2Lj-1 {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #448086;
        margin-top: 5px;
        margin-bottom: 10px; }
      .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizeCardSize__3HL6u .oldBuyModals_braceletSizeCode__2JN_T {
        margin-top: 10px; }
      .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizeCardSize__3HL6u svg {
        font-size: 15px; }
    .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik p {
      color: #448086;
      font-weight: bold; }
    .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizePicker__2Tu6o {
      margin: 1em; }
      .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_sizeCard__2Hwik .oldBuyModals_sizePicker__2Tu6o label {
        font-size: 15px;
        margin-left: -3px; }
  .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_braceletsSelectorGrid__17F0E {
    display: flex;
    flex-direction: row;
    align-items: center; }
    @media (max-width: 480px) {
      .oldBuyModals_sizeCardContainer__2MajR .oldBuyModals_braceletsSelectorGrid__17F0E {
        flex-direction: column-reverse; } }

.oldBuyModals_sizeSelectorActions__1Y4sg {
  display: flex; }

@media (min-width: 768px) {
  .oldBuyModals_sizeSelectorMobileButton__3SSDe {
    display: none !important; } }

.oldBuyModals_sizeStock__24a4L {
  color: red !important;
  margin: 0;
  padding-bottom: 0.5rem; }

.oldBuyModals_sizeCardContainerCart__6O2Oc {
  justify-content: center;
  margin-bottom: 1em !important; }
  .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-left: solid 5px #4FA9B3; }
    @media (min-width: 480px) {
      .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m {
        padding: 0 1rem;
        margin: 1rem; } }
    @media (max-width: 480px) {
      .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m {
        width: 100% !important; } }
    .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m .oldBuyModals_sizeCardImg__1XEbu {
      width: 100px;
      margin: 1em !important; }
      @media (max-width: 480px) {
        .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m .oldBuyModals_sizeCardImg__1XEbu {
          width: 90px; } }
    .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m p, .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m span {
      color: #448086;
      font-weight: bold;
      margin: 5px; }
    .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m span {
      margin-top: 5px; }
    .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m .oldBuyModals_productImageBox__1HlgQ {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .oldBuyModals_sizeCardContainerCart__6O2Oc .oldBuyModals_sizeCardCart__KEG8m .oldBuyModals_productDetails__1Ld8V {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }

.oldBuyModals_cartItem__JC-wS {
  display: flex !important;
  justify-content: center;
  padding: 10px 0 !important; }

.oldBuyModals_discountContainer__1b-8i {
  display: flex;
  align-items: center; }
  .oldBuyModals_discountContainer__1b-8i .oldBuyModals_discountButton__XQTW4 {
    margin: 10px;
    border: 1px solid #4FA9B3;
    color: #50a7b4 !important; }
  .oldBuyModals_discountContainer__1b-8i .oldBuyModals_discountButton__XQTW4:disabled {
    border: 1px solid #aaaaaa;
    color: #e0e0e0 !important; }

.oldBuyModals_snackTotalBraceletsMessage__3HR92 {
  font-weight: bold; }

.oldBuyModals_snackTotalBracelets__1vsiY {
  padding: 15px; }
  .oldBuyModals_snackTotalBracelets__1vsiY button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer; }
  .oldBuyModals_snackTotalBracelets__1vsiY span {
    background-color: darkorange; }
  .oldBuyModals_snackTotalBracelets__1vsiY a {
    color: #fff; }
  .oldBuyModals_snackTotalBracelets__1vsiY a:hover {
    color: blue; }

.oldBuyModals_packagingSection__2YoJI {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.oldBuyModals_packagingCheck__2oQRZ {
  align-self: flex-start !important; }

.oldBuyModals_packagingCheckLabel__10kxA {
  color: #716b77 !important; }

.oldBuyModals_packagingQuantitySelector__39Uaq {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  padding: 5px; }
  @media (max-width: 480px) {
    .oldBuyModals_packagingQuantitySelector__39Uaq {
      width: 75%; } }

.oldBuyModals_packagingImgContainer__3Oipt {
  display: flex;
  align-items: center;
  justify-content: center; }

.oldBuyModals_packagingImg__15bAm {
  width: 270px;
  height: auto;
  border-radius: 15px;
  margin: 1em !important; }
  @media (max-width: 480px) {
    .oldBuyModals_packagingImg__15bAm {
      height: auto;
      width: 220px; } }

.oldBuyModals_packagingInput__3gaGy {
  width: 100px; }

.oldBuyModals_packagingCartImg__1D8GN {
  margin-top: 0.5em;
  width: 100%;
  border-radius: 15px; }
  @media (max-width: 480px) {
    .oldBuyModals_packagingCartImg__1D8GN {
      width: 150px; } }

.oldBuyModals_priceChip__1ZsIY {
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 1.5rem !important; }

.oldBuyModals_totalPriceChip__3QaxA {
  padding: 22px !important;
  font-size: 2rem !important;
  color: #ffffff !important;
  background-color: #50a7b4 !important;
  margin-left: 1em; }

.oldBuyModals_oldPriceChip__2SAWl {
  margin-bottom: 1em;
  margin-top: 0.5em;
  text-decoration: line-through !important; }

.oldBuyModals_totalPriceWrapper__7rlbs {
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 1rem;
  flex-direction: column; }

.oldBuyModals_parentShippingPriceBox__1I_uM {
  display: flex;
  justify-content: center;
  align-items: center; }

.oldBuyModals_shippingPriceBox__aA8iE {
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-left: 5px solid #CCBD88;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .oldBuyModals_shippingPriceBox__aA8iE h6 {
    text-align: center; }
  .oldBuyModals_shippingPriceBox__aA8iE .oldBuyModals_centerContainer__2BEVh {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }

.oldBuyModals_offerCard__1EJhO {
  margin: auto;
  width: 75%;
  flex-direction: column;
  padding: 1em !important;
  text-align: center !important;
  border-radius: 50px !important;
  margin-bottom: 0.5em !important;
  margin-top: 0.5em !important;
  background-image: linear-gradient(-20deg, #fdbeb0 0%, #fdf8bd 100%); }
  @media (min-width: 768px) {
    .oldBuyModals_offerCard__1EJhO {
      width: 25%; } }

.oldBuyModals_dialogActions__IckYx, .oldBuyModals_dialogActionsFirstStep__3vJaF {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding: 0.5em;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 0 !important;
  z-index: 300;
  text-align: center; }

.oldBuyModals_dialogActionButton__3Y5cK {
  padding: 0.5em 5em !important;
  margin: 5px !important;
  border-radius: 1em !important;
  color: #ffffff !important;
  background-color: #50a7b4 !important; }

.oldBuyModals_dialogActionButton__3Y5cK:disabled {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: initial !important;
  color: #808080 !important; }

.oldBuyModals_disabledButton__Xzghg {
  padding: 0.5em 5em !important;
  margin: 5px !important;
  border-radius: 1em !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  color: #808080 !important;
  box-shadow: none !important;
  cursor: default !important; }

.oldBuyModals_loadingPaymentActionsModal__gdzbR [class^="MuiPaper-root"] {
  transition: height .45s ease-in-out !important; }

.oldBuyModals_redsysAnimationContainer__1bJWw {
  transition: opacity .45s ease-in-out !important;
  opacity: 0;
  text-align: center; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .oldBuyModals_cityZipcodeContainer__1IV30 {
    display: flex;
    background-color: #4fb457;
    flex-direction: column;
    justify-content: space-between; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.BeforeCloseAlertModal_dialogContentText__1MZ_l {
  font-size: 20px !important;
  margin-bottom: 1em !important;
  text-align: center; }

.BeforeCloseAlertModal_mainActionButton__2VgVZ {
  border-radius: 25px !important;
  padding: 1rem 3rem !important; }

.BeforeCloseAlertModal_closeModalIcon__39Ykq {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BeforeCloseAlertModal_dialogActions__2Kh7G {
  justify-content: center !important; }

.BeforeCloseAlertModal_sendingDataAnimation__2SnQp {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterModal_parentContainer__3FC0O {
  display: flex;
  justify-content: center; }

.NewsletterModal_dialogContentText__1qbN0 {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important; }

.NewsletterModal_mainActionButtonContainer__1HZ3B {
  display: flex;
  justify-content: center; }

.NewsletterModal_mainActionButton__Wsn2I {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.NewsletterModal_dialogActions__1Xi_l {
  justify-content: center !important; }

.NewsletterModal_closeModalIcon__14i5w {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterModal_sendingDataAnimation__3g-9N {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PresaleModal_parentContainer__1OTY- {
  display: flex;
  justify-content: center; }

.PresaleModal_dialogContentText__3Bb61 {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.PresaleModal_mainActionButtonContainer__2vtx2 {
  display: flex;
  justify-content: center; }

.PresaleModal_mainActionButton__DSoWR {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.PresaleModal_dialogActions__1hAyp {
  justify-content: center !important; }

.PresaleModal_closeModalIcon__1AKLN {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.PresaleModal_sendingDataAnimation__2jcbP {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterSection_parentContainer__25j3Y {
  display: flex;
  justify-content: center; }

.NewsletterSection_dialogContentText__2ktsf {
  font-size: 20px !important;
  text-align: center !important;
  margin-bottom: 1em !important; }

.NewsletterSection_mainActionButtonContainer__3YUYn {
  display: flex;
  justify-content: center; }

.NewsletterSection_mainActionButton__3MDlH {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }
  @media (min-width: 768px) {
    .NewsletterSection_mainActionButton__3MDlH {
      margin-left: 2rem !important; } }

.NewsletterSection_dialogActions__3T9SU {
  justify-content: center !important; }

.NewsletterSection_closeModalIcon__1aAIR {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterSection_sendingDataAnimation__1nso1 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

@media (min-width: 768px) {
  .NewsletterSection_cardContent__GZZa7 {
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 768px) {
  .NewsletterSection_inputAndClaimParentContainer__15jx6 {
    margin-right: 2rem;
    margin-left: 2rem; } }

.NewsletterSection_inputAndButtonContainer__971w0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .NewsletterSection_inputAndButtonContainer__971w0 {
      flex-direction: row; } }

.NewsletterSection_succeedAnimationContainer__RXL7n {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .NewsletterSection_succeedAnimationContainer__RXL7n .NewsletterSection_succeedAnimationClaim__1ppBg {
    margin-top: 1rem; }

/*font-family*/
/*font-size*/
/*font-weight*/
@keyframes ImpetraThanksPage_floatBracelet__nJtU2 {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes ImpetraThanksPage_floatVerticalBracelet__2v9Eg {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes ImpetraThanksPage_backgroundGradient__2pZRJ {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes ImpetraThanksPage_backgroundGradient2__ruxNH {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.ImpetraThanksPage_rootContainer__1qbi0 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ImpetraThanksPage_backgroundGradient__2pZRJ 15s ease-in-out infinite;
  overflow: hidden; }

.ImpetraThanksPage_title__2MEVj {
  font-family: Comfortaa, sans-serif !important;
  color: #FFFFFF !important;
  padding: 1rem;
  text-align: center !important; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_title__2MEVj {
      padding: 4rem 2rem 2rem;
      font-size: 2rem !important; } }

.ImpetraThanksPage_animationAndHashtagContainer__34-TA {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 !important; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_animationAndHashtagContainer__34-TA {
      flex-direction: row; } }

.ImpetraThanksPage_hashtag__2w7c4 {
  font-family: Comfortaa, sans-serif !important;
  color: #FFFFFF !important;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.05rem !important; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_hashtag__2w7c4 {
      font-size: 1.75rem !important; } }
  .ImpetraThanksPage_hashtag__2w7c4 .ImpetraThanksPage_hashtagIcon__2UaBe {
    font-family: Comfortaa, sans-serif !important;
    font-size: 3rem; }
    @media (min-width: 768px) {
      .ImpetraThanksPage_hashtag__2w7c4 .ImpetraThanksPage_hashtagIcon__2UaBe {
        font-size: 5rem !important; } }

.ImpetraThanksPage_logosContainer__bhRHT {
  display: flex;
  justify-content: center;
  align-items: center; }

.ImpetraThanksPage_pulsidLogo__13UHu {
  width: 60%; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_pulsidLogo__13UHu {
      width: 10rem; } }

.ImpetraThanksPage_impetraLogo__Kg2Ju {
  width: 85%; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_impetraLogo__Kg2Ju {
      width: 13rem; } }

