@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";

.navbar {
  width: 100%;
  position: fixed;
  z-index: 70;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #4FA9B3; // fallback for not transparent compatible navigators
  background-color: transparent;

  .logoImg {
    width: 75px;
    cursor: pointer;
    margin-left: 0.2rem;
    @media (min-width: $tablet) {
      margin-left: 2.5%;
      width: 100px; } }

  .buttonsContainer {
    display: flex;
    align-items: center;
    .buttonsContent {
      display: flex;
      flex-direction: row;
      @media (max-width: 767px) {
        display: none; }
      @media (max-width: $tablet) {
        display: none;
        font-size: $medium-font-size; } }
    .actionsContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 340px) {
        flex-direction: column; }
      button {
        @media (max-width: $tablet) {
          font-size: $medium-font-size; } } } } }

.menuButton {
  //color: $color-secondary-1-0  !important
  color: #ffffff !important;
  margin-right: 0.5rem !important;
  @media (min-width: $tablet) {
    display: flex !important; }
  @media (min-width: $computer) {
    display: none !important; } }

.nestedList {
  padding-left: 1.8rem !important; }

.actionButton {
  //background-color: $color-secondary-1-0  !important
  //color: $complementary-concrete-color !important
  background-color: #ffffff !important;
  color: #000 !important;
  margin: 20px 10px !important;
  //padding: 10px !important
  //border-radius: 6px
  cursor: pointer;
  font-size: $large-font-size;
  @media (max-width: $tablet) {
    font-size: $medium-font-size;
    min-width: auto !important;
    display: none !important; } }

.actionButtonOutlined {
  //border-color: $color-secondary-1-0  !important
  //color: $color-secondary-1-0  !important
  border-color: #ffffff !important;
  color: #ffffff !important;
  margin: 20px 10px !important;
  //padding: 10px !important
  //border-radius: 6px
  cursor: pointer;
  font-size: $large-font-size;

  @media (max-width: $tablet) {
    font-size: $medium-font-size;
    min-width: auto !important;
    display: none !important; } }


.actionButtonGetIt {
  //color: $color-secondary-1-0  !important
  color: #ffffff !important;
  margin: 20px 10px !important;
  font-weight: bold !important;
  @media (min-width: $tablet) {
    font-size: $medium-font-size;
    display: none !important; }
  @media (max-width: $mobile) {
    display: none !important; } }



.landingButton {
 font-size: $large-font-size;
 text-decoration: none;
 //color: $secondary-color
 color: #ffffff;
 margin: 20px;
 padding: 10px;
 border-radius: 6px;
 cursor: pointer; }


.logoutModal {
  .contentModal {
    min-width: 350px;
    @media (max-width: $tablet) {
      min-width: 100px; } } }

.hiddenScrollToTopLink {
  display: none; }

.activeBraceletButton {
  color: $color-secondary-1-0  !important;
  @media (max-width: $tablet) {
    display: none !important; } }

.offerBanner {
  width: 100%;
  padding: 3em;
  text-align: center;
  font-size: $medium-font-size;
  background-color: black;
  color: white; }
.spanishProductIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  span {
    color: #fff;
    font-size: 12px; } }
